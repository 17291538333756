<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">
  
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <h3>Privacy Policy</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">Privacy Policy</li>
                    </ol>
                </div>
            </div>
  
        </div>
  
    </div>
    <!-- az-header -->
    <div class="az-content az-content-dashboard lp-content-dashboard pb-0 inner-main inner-about">
        
        <div class="w-100 ">               
          <div class="container">
            <div class="row w-100">
                
                <div class="col-lg-12 col-md-12">
                  <div class="flex-center-1">
                    <div class="flex-center-2">
                      <!-- <h3 class="inner-tl"> WHO  <span>WE ARE:</span></h3> -->
                        <div class="list-group">
                          <p>We let you know what information we collect when you use our products and services, why we collect it, and how we safeguard it. Our privacy policies, videos, and product designs aim to make these things clear so that you can make informed choices about which products to use and how to use them.</p>

                          <h3 class="inner-tl"> <span>Grant </span> </h3> 
                          <h4 class="tl-3-lp">1. Why we collect Personal Information Information?</h4>
                          <p> We collect personal information from you to identify you, to ensure that we provide you with the correct feedback regarding your performance in various tests, and to help us deliver a superior educational experience. Information collected enables us to give you convenient access to our service offerings and products, and may help us tailor your educational experience.</p>
                          
                          <h4 class="tl-3-lp">2. What Information do we collect and how we use it:</h4>
                          <h5 class="tl-4-lp pt-1">Login Information</h5>
                          <p>When you access our website, our servers automatically record information that your browser sends whenever you visit a website. These server logs may include information such as your web request, Internet Protocol address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser.</p>

                          <h5 class="tl-4-lp">Cookies</h5>
                          <p> When you visit Lawsuit, we send one or more cookies - a small file containing a string of characters - to your computer or other device that uniquely identifies your browser. We use cookies to improve the quality of our service, including for storing user preferences, improving search results and ad selection, and tracking user trends, such as how people search.</p>

                          <h5 class="tl-4-lp">User Communication</h5>
                          <p> When you send email or other communication to us, we may retain those communications in order to process your inquiries, respond to your requests and improve our services.</p>

                          <h5 class="tl-4-lp">Links</h5>
                          <p> We may present links in a format that enables us to keep track of whether these links have been followed. We use this information to improve our customized content. Clicking on links may take you to sites outside our domain. We are not responsible for the privacy practices of other web sites. We encourage our users to be aware when they leave our site to read the privacy statements of each and every web site that collects personally identifiable information. This Privacy Policy applies solely to information collected by our Website.</p>

                          <h5 class="tl-4-lp">Alerts</h5>
                          <p> We may alert you by email if we update our website in a way that we believe you should know about. Email alerts are usually prompted by new features on the websites, or substantial improvements in product offerings.</p>

                          <h5 class="tl-4-lp">Public Forums</h5>
                          <p> When you use certain features on our website like the discussion forums and you post or share your personal information such as comments, messages, files, photos, will be available to all users, and will be in the public domain. All such sharing of information is done at your own risk. Please keep in mind that if you disclose personal information in your profile or when posting on our forums this information may become publicly available.</p>

                          <h4 class="tl-3-lp">3. Data Security:</h4>
                          <p>If use a feature requiring payment of a Subscription fee, we transmit your credit card data to our bank for processing. If we encounter a problem with payment, we may review the order information with you and our bank to resolve it. We do not otherwise disclose your credit card information. We do not share your email address or your personal information with third party marketers.</p>
                          <p>We offer secure pages to collect sensitive information on our order form, such as credit card information. We also use administrative, physical and technical precautions to help protect the confidentiality, security and integrity of personal information stored on our system. We host the Site at a commercial-grade data center that employs extensive security practices. While no computer system is completely secure, we believe the measures implemented by our Site reduce the likelihood of security problems to a level appropriate to the type of data involved.</p>

                          <h4 class="tl-3-lp">4. If you have a question:</h4>
                          <p>We are posting this Policy in an effort to be a good web citizen. If you have a question, please bring it to our attention at privacy@lawsuitcasefinder.com or write to:</p>

                          <p> Levons Technologies Pvt. Ltd.,<br>
                            A/S-3, Raj Plaza Complex.<br>
                            Nr. Sonal Cross Road,<br>
                            Gurukul Road,<br>
                            Memnagar,<br>
                            Ahmedabad - 380 052<br>
                            Gujarat,<br>
                            India.
                            
                            </p>


                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
          </div>
                   
        </div>
          
    </div>
    <app-footer></app-footer>
  </div>