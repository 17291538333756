import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');


@Injectable({
  providedIn: 'root'
})
export class CasedetailService {

  constructor(
    private httpclient: HttpClient,
    @Inject(DOCUMENT) private doc: any
  ) { }
  public getLatestCase(payload, adddocument, userid): Observable<any> {

    return this.httpclient.post<any>(`${environment._apiUrl}/api/welcome/getlatestcasedataes?adddocument=${adddocument}&userid=${userid}`, payload, {
      headers
    });
  }
  createLinkForCanonicalURL() {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);
 }
}
