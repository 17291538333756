import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticlesService } from 'src/app/services/articles.service';
import { AuthService } from 'src/app/services/auth.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { LoaderService } from 'src/app/services/message/loader.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private encdec: EncDecService,  
    private lservice: LoaderService,
    private aservice:ArticlesService,
    private titleService: Title,
    private router: Router,
  ) { }
  articlesList :any= [];
  ngOnInit(): void {
  this.getArticles();
  }

  getArticles() {

    this.aservice.getArticles().subscribe(data => {
      if (data.flag) {
        this.articlesList = data.outdata;
      }
    })
  }
}
