<footer>
    <div class="lp-footer" id="divmainfooter">
      <div class="container">
        <div class="lp-footer-inner">
            <h3>Get Started with the mobile app!</h3>
            <p> Our purpose is to develop Law portal wherein worldwide Legal Information is available. Also where Legal Professional can get any information regarding legal field, </p>
            <ul class="footer-app-block">
              <li><a href="https://play.google.com/store/apps/details?id=com.fipl.Lawsuit" target="_blank"> <img src="assets/img/gp.png" alt="lawsuit app"  width="135px" height="40px"> </a></li>
              <li><a href="https://apps.apple.com/in/app/lawsuit-the-unique-case-finder/id944129656"  target="_blank"> <img src="assets/img/app.png" alt="lawsuit app" width="135px" height="40px"> </a></li>
            </ul>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="lp-footer-inner-bt-top">
              <ul>
                <li><a href="#">Home</a> <span>|</span></li>
                <li><a href="#" [routerLink]="['/about']">About Us</a> <span>|</span></li>
                <li><a href="#" [routerLink]="['/datacoverage']">Coverage Area</a> <span>|</span></li>
                <li><a href="#" [routerLink]="['/features']">Features</a> <span>|</span></li>
                <li><a href="#" [routerLink]="['/packages']">Packages</a> <span>|</span></li>
                <!-- <li><a href="#" [routerLink]="['/inquiry']">Inquiry</a> <span>|</span></li> -->
                <li><a href="#" [routerLink]="['/termsandcondition']">Terms and Conditions</a> <span>|</span></li>
                <li><a href="#" [routerLink]="['/privacypolicy']">Privacy Policy</a> <span>|</span></li>
                <li><a href="#" [routerLink]="['/contact']">Contact Us</a> </li>
              </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="lp-footer-inner-bt-left-logo">
              <img src="assets/img/f-logo.png" class="img-fluid" alt="lawsuitcasefinder"> 
            </div>
          </div>
          <div class="col-sm-6">
            <div class="lp-footer-inner-bt-left">
            <p>  © Copyright Levons Technologies Pvt. Ltd. 2020. All Right reserved.</p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="lp-footer-inner-bt-right">
              <ul>
                <li><a href="https://www.facebook.com/lawsuitcasefinder"> <i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.linkedin.com/in/lawsuit-the-unique-case-finder-b391b693/"> <i class="fab fa-linkedin"></i></a></li>
                <li><a href="https://www.youtube.com/@lawsuit-theuniquecasefinde5290"> <i class="fab fa-youtube"></i></a></li>
                <li><a href="https://www.instagram.com/lawsuit.official/"> <i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>