import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { ToastService } from 'src/app/services/message/toast.service';
@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {

  constructor(
    private cService: ContactService,
    private mService: ToastService
  ) { }
  inquiry: any = {};
  ngOnInit(): void {
  }
  saveInquiry(mainform) {
    if (mainform.valid) {
      this.cService.addInquiry(this.inquiry).subscribe(data => {
        if (data.flag) {
          this.mService.generateMessage("SUCCESS", data.message, "Success");
          this.inquiry = {};
        } else {
          this.mService.generateMessage("ERROR", data.message, "Failed");
        }
      })
    }
  }
}
