<div class="lp-main lp-main-inner">
  <div class="lp-header-bg">

      <app-navigation></app-navigation>
      <div>
          <div class="container">
              <div class="lp-banner-text">
                  <h3>About</h3>
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                      <li class="breadcrumb-item active">About</li>
                  </ol>
              </div>
          </div>

      </div>

  </div>
  <!-- az-header -->
  <div class="az-content az-content-dashboard lp-content-dashboard pb-0 inner-main inner-about">
        
    <div class="w-100 ">               
      <div class="container">
        <div class="row w-100">
            <div class="col-lg-6 col-md-6">
              <div class="feature-mobail-screen">
                <img src="assets/img/about-stk.png" class="img-fluid">
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="flex-center-1">
                <div class="flex-center-2">
                  <h3 class="inner-tl"> WHO  <span>WE ARE:</span></h3>
                    <div class="list-group">
                      <p><span class="abt-62">Lawsuit – The Unique Case Finder<sup>®</sup></span> is catering its trusted services to its esteemed and recognised clients since 2006. Over the journey we are able to collect proudly announced testimonials from our satisfied on-board users.</p>
                      <p><span class="abt-62">Lawsuit – The Unique Case Finder<sup>®</sup></span> is extension of services from Levons Technologies Pvt. Ltd.<sup>®</sup> experienced and catering worldwide legal information to attorneys and public across the globe. It provides Legal Research database from highly trusted available sources which includes bundle of publishers from world and legal solution providers.</p>
                      <p><span class="abt-62">Lawsuit – The Unique Case Finder<sup>®</sup></span> facilitates users to provide high-end techno legal search engine which can be very much useful for research on various aspects of law. Our valuable users are legal fraternities like Hon’ble Judges, Judicial Officers, Judicial Academy, Law Students, Practicing Lawyers, Small & Large Law Firms, Banking, Insurance & Financial Institutions, Law Colleges & Universities, Public & Private Sectors, Prosecution Departments, Administrative Officers, Police Officers & Departments.</p>
                      <p>It can be defined as one stop solution to all your legal queries.</p>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="container">
        <div class="row w-100 about-block-top-mar dw-767">
          <div class="col-md-5 order-sm-2">
            <div class="feature-mobail-screen">
              <img src="assets/img/about-2.png" class="img-fluid">
            </div>
          </div>           
            <div class="col-md-7 order-sm-1">
              <div class="flex-center-1">
                <div class="flex-center-2">
                  <h3 class="inner-tl uppercase"> Establishment <span> </span></h3>
                   <p>Proprietorship firm named Levons Technologies started in the year of 2007 by Mr. Tejas Dave, they having an advantage of high skill technical development and market research. According to see the need of Legal fraternity they planned for development of Legal Research System and it was successfully launched legal research system named Lawsuit – <span class="abt-62">Lawsuit – The Unique Case Finder<sup>®</sup></span>.</p>
                  <p>
                    Incorporation of the company named Levons Technologies Pvt. Ltd.<sup>®</sup> landed its footprint in the year 2011 and from then rigorously working with motive to keep you up to date with all the legal releases covering bands of All the Courts in India. </p>
                    <p>An Ideology of founder & CMD Mr. Tejas Dave and his young & dynamic team’s hard work have brought Levons Technologies Pvt. Ltd.<sup>®</sup> to stand with confidence and be one of the recognised as a trusted brand name in the market. Serving with such a unique concept which is distributing rays of hopes to complex legal queries. </p>

                    <p><strong>Mr. Tejas Dave</strong> have logged in all his experience and tried to offer this fast-moving world to gather at a common platform for solving and studying the legal solutions, where you can access all the case laws / judgements released by respective Hon’ble Supreme Court of India along with All High Courts of India.</p>
                    <p class="p-span-bold"> <span class="abt-62">Lawsuit – The Unique Case Finder<sup>®</sup></span> is Ice breaker product extended by <span>Levons Technologies Pvt. Ltd.<sup>®</sup></span></p>
                </div>
              </div>
            </div>  
                           
        </div>            
      </div>
       

      <div class="container">
        <div class="row w-100 about-block-top-mar">
          <div class="col-md-4">
            <div class="mv-3blocl">
              <div class="mv-3blocl-img">
                <img src="assets/img/mision.jpg" alt="mis" class="img-fluid">
                <div class="mv-3blocl-tl"> <h3>Mission</h3> </div>
              </div>
              <div class="mv-3blocl-inner">
              <!-- <span class="tl-tejas-dave">Mission</span> -->
              <p>To be a globally recognized company which offer innovative & technology driven solutions to Legal fraternity in terms of Legal Research, Office Management and many more platforms for their professional growth. </p>
            </div>
            </div>
          </div> 
          <div class="col-md-4">
            <div class="mv-3blocl">
              <div class="mv-3blocl-img">
               <img src="assets/img/vision.jpg" alt="mis" class="img-fluid">
               <div class="mv-3blocl-tl"> <h3>Vision</h3> </div>
             </div>
             <div class="mv-3blocl-inner"> 
              <!-- <span class="tl-tejas-dave">Vision</span> -->
              <p>To become one of the world’s leading companies bringing innovations to improve the way of legal research of the legal fraternity across the world.</p>
            </div>
          </div> 
        </div>
          <div class="col-md-4">
            <div class="mv-3blocl">
              <div class="mv-3blocl-img">
                <img src="assets/img/value.jpg" alt="mis" class="img-fluid">
                <div class="mv-3blocl-tl"> <h3>Values</h3> </div>
              </div>
              <div class="mv-3blocl-inner">
              <!-- <span class="tl-tejas-dave">Values </span> -->
              <p>We are responsible, accountable, respectful, effective, efficient public stewards. We promote honesty, integrity and transparency in all we do. We encourage innovation to meet challenges. </p>
            </div>
          </div>
          </div>                
                            
        </div>            
      </div>

      <div class="tl95">
      <div class="timeline">
        <div class="container left">
          <div class="date">2006</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
            <h2>Launching of offline Lawsuit </h2>
            <p>
              <span class="abt-22">The Unique Case Finder<sup>®</sup></span> with limited data of Supreme Court of India with 4 major High Courts.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">2008</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
            <h2>Launching of Lawsuit</h2>
            <p>
              <span class="abt-22">The Unique Case Finder<sup>®</sup></span> with All High Courts data since 1950
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="date">2010</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
            <h2></h2>
            <p>
              Addition of Privy Council Cases along with Chartered Courts starting since 1871. Also added UK Case Laws since 1660.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">2011</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">                
            <p>
              Releasing India’s first compatible version on MAC / UBUNTU / LINUX of Lawsuit Offline Software with High-end unique features with real time updates. 
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="date">2012</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
            <h2> Launching online version of Lawsuit</h2>
            <p>
              <span class="abt-22">The Unique Case Finder<sup>®</sup></span>
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">2013</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
            <h2>Launching of Android / iOS Mobile Application of Lawsuit</h2>
            <p>
              <span class="abt-22">The Unique Case Finder<sup>®</sup></span>
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="date">2014</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
            <h2>   New offline version released with some unique features</h2>
            <p>
              New product launch Lawsuit Pro – Office on your Desk<sup>®</sup> Complete Lawyer’s and Law Firm Management System.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">2015  </div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
             <p>
              Addition of Consumer Cases along with Tribunals
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="date">2016</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
             <p>
              Launching new featured website & Android / iOS Application with enhancement of user experience
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="date">2018</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
             <p>
              New offline version released with some unique features & enhancement of user experience.
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="date">2020</div>
          <i class="icon far fa-calendar-alt"></i>
          <div class="content">
             <p>
              Technological Up gradation in Online (Web), Android & iOS version.
            </p>
          </div>
        </div> 
      </div>
    </div>
      <!-- <div class="container">
        <div class="row w-100 about-block-top-mar">
          
          
        </div>
      </div> -->


      <div class="container">
        <div class="row w-100 pt-4">
          <p class="text-center w-100"> Now E-library Supports on <img src="assets/img/about-bt-img.png" alt="right" class="img-fluid about-bt-img"></p>
        </div>
      </div>          
    </div>
      
</div>
  <app-footer></app-footer>
</div>