<div class="lp-main lp-main-inner">
  <div class="lp-header-bg">

    <app-navigation></app-navigation>
    <div>
      <div class="container">
        <div class="lp-banner-text">
          <h3>Registration</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
            <li class="breadcrumb-item active">Registration</li>
          </ol>
        </div>
      </div>

    </div>

  </div>
  <section class="testimonial " id="testimonial">
    <div class="container m-0 p-0 form-auto">
      <div class="row m-0">
        <div class="row w-100 registration-main">

          <div class="col-md-7">
            <div class="registration-left-text">
              <div class="rlt-i">

                <div class="row m-0">
                  <div class="row col-md-12">
                    <div class="col-md-6">
                      <div class="rlt-1">
                        <div>
                          <h3><span class="num" style="font-weight: 400;">0</span></h3>
                          <div class="hr"></div>
                          <h2>Total Number of Users</h2>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="rlt-2 number-diy">
                        <div>
                          <h3><span class="num1" style="font-weight: 400;">0</span></h3>
                          <div class="hr"></div>
                          <h2>Total Number of Judgments</h2>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                      <div class="rlt-3">
                        <div>
                          <h3><span class="num2" style="font-weight: 400;">0</span></h3>
                          <div class="hr"></div>
                          <h2>Total Number of Bare Acts</h2>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3"></div>
                  </div>

                </div>



              </div>

            </div>
          </div>
          <div class="col-md-5">
            <form novalidate  #mainform="ngForm">
              <div class="reg-rt-main-inner reg-right-block">
                <div class="row w-100 ">

                  <div class="col-lg-6 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">
                      <input aria-label="fname" type="text" placeholder="First Name" [(ngModel)]="user.fname" pattern="^[a-zA-Z]+$"
                        name="fname" data-name="fname" [required]="true" data-prefix="First Name" data-type="text" maxlength="50">
                      <span>First Name</span>
                      <div *ngIf="mainform.form.controls.fname.errors?.pattern" style="color: red;">Please enter only text characters.</div>
                    </label>
                  </div>
                  <div class="col-lg-6 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">
                      <input aria-label="lanme" type="text" placeholder="Last Name" [(ngModel)]="user.lname" pattern="^[a-zA-Z]+$"
                        name="lname" data-name="lname" [required]="true" data-prefix="Last Name" data-type="text" maxlength="50">
                      <span>Last Name</span>
                      <div *ngIf="mainform.form.controls.lname.errors?.pattern" style="color: red;">Please enter only text characters.</div>
                    </label>
                  </div>
                 
                  <div class="col-lg-12 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">
                      <input aria-label="Email" type="text" placeholder="Email" [(ngModel)]="user.email" name="Email" maxlength="100"
                        autocomplete="new-email" data-name="Email" [required]="true" data-prefix="Email"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        data-type="text"> 
                      <span>Email Addrress</span>
                      <div *ngIf="mainform.form.controls.Email.errors?.pattern" style="color: red;">Please enter valid email address</div>
                    </label>
                     
                  </div>
                  <div class="col-lg-12 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">
                      <input aria-label="Mobile" type="number" placeholder="Mobile" [(ngModel)]="user.mobile" maxlength="10" minlength="10"
                        name="Mobile" data-name="Mobile" [required]="true" data-prefix="Mobile" data-type="text" pattern="^[0-9]{10}$">
                      <span>Mobile No</span>
                       <div *ngIf="mainform.form.controls.Mobile.errors?.pattern" style="color: red;">Please enter valid active mobile number for SMS OTP Verification.</div>
                    </label>
                  </div>
                  <div class="col-lg-12 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">
                      <input aria-label="username" type="text" placeholder="User Name" [(ngModel)]="user.loginID" name="loginID" maxlength="50"
                       data-name="loginID" [required]="true" data-prefix="User Name" pattern="^\S[a-z0-9]+$"
                        data-type="text">
                        <div *ngIf="mainform.form.controls.loginID.errors?.pattern" style="color: red;">Please enter a valid username without spaces in lowercase characters.</div>
                      <span>User Name</span>
                    </label>
                  </div>
                  <div class="col-lg-6 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">

                      <input aria-label="Password" type="password" placeholder="Password" [(ngModel)]="user.password"  maxlength="50"
                        autocomplete="new-password" name="Password" data-name="Password" [required]="true"
                        data-prefix="Password" data-type="text">
                      <span>Password</span>
                    </label>
                  </div>
                  <div class="col-lg-6 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">
                      <input aria-label="RePassword" type="password" placeholder="Confirm  Password" maxlength="50"
                        [(ngModel)]="user.repassword" name="repassword" data-name="repassword" [required]="true"
                        [ngClass]="!validpass?'invalid':''" (keyup)="validpass=(user.password==user.repassword)"
                        data-prefix="Confirm  Password" data-type="text">
                      <span>Confirm Password</span>
                    </label>
                  </div>

                  <!-- <div class="col-lg-12 col-sm-6 vs-text-pad-r">
                    <div class="d-flex ">
                      <div class="w-100 select-mar-bt">
                        <div class="select">
                          <select aria-label="choosecountry" name="Packages" data-name="Packages"
                            [(ngModel)]="user.subscriptionplanmasterid" [required]="true" data-prefix="Packages"
                            data-type="select" class="select-text">

                            <option *ngFor="let package of packagelist" [value]="package.subscriptionPlanMasterID">
                              {{package.packageName}}
                            </option>
                          </select>
                          <span class="select-highlight"></span>
                          <span class="select-bar"></span>
                          <label class="select-label select-label-small"> Select Packages</label>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-lg-12 col-sm-6 vs-text-pad-r">
                    <div class="d-flex ">
                      <div class="w-100 select-mar-bt">
                        <div class="select">

                          <select aria-label="choosecountry" name="Country" (change)="getState()" data-name="Country"
                            [(ngModel)]="user.countryMasterID" [required]="true" data-prefix="Country"
                            data-type="select" class="select-text">

                            <option *ngFor="let country of countrylist" [value]="country.countryMasterID">
                              {{country.countryName}}
                            </option>
                          </select>
                          <span class="select-highlight"></span>
                          <span class="select-bar"></span>
                          <label class="select-label select-label-small"> Select Country</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-6 vs-text-pad-r">
                    <div class="d-flex ">
                      <div class="w-100 select-mar-bt">
                        <div class="select">
                          <select aria-label="choosecountry" name="State" data-name="State"
                            [(ngModel)]="user.stateMasterID" [required]="true" data-prefix="State" data-type="select"
                            class="select-text">
                            <option *ngFor="let state of statelist" [value]="state.stateMasterID">
                              {{state.stateName}}
                            </option>
                          </select>

                          <span class="select-highlight"></span>
                          <span class="select-bar"></span>
                          <label class="select-label select-label-small"> Select State</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-6 vs-text-pad-r">
                    <label class="pure-material-textfield-outlined w-100">
                      <input aria-label="City" type="text" placeholder="City" [(ngModel)]="user.city" name="city" maxlength="50"
                        data-name="City" [required]="true" data-prefix="City" data-type="text">
                      <span>City</span>
                    </label>
                  </div>
                  <div class="col-lg-12 col-sm-12 vs-text-pad-r pb-3">
                    <label class="w-100 remember-labal">
                      <input class="form-check-input" type="checkbox" value="user.ischeckterm"
                        [(ngModel)]="user.ischeckterm" name="ischeckterm"> <span> I Agree </span> <span><a
                          href="/termsandcondition">Terms
                          and Conditions:</a> </span>
                    </label>
                  </div>
                  <div class="col-lg-12 col-sm-12 search-recovered-btn-top-pad">
                    <div class="serch-btn-rt_des_left_mobail">
                      <button role="button" aria-label="makeMeLogin" class="form-btn yello-btn" appValidation
                        [form]="mainform" [validparent]="'form-group'" (click)="saveUser(mainform)">Submit</button>
                      <!-- <a href="#registration" class="form-btn yello-btn" data-toggle="modal" data-effect="effect-scale">
                              Submit1
                            </a> -->
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-12 reg-rt-main">
        </div>
      </div>
    </div>
  </section>
  <br />
  <app-footer></app-footer>
  <div id="registration" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <form novalidate #ngactivationForm="ngForm">
          <div class="modal-header">
            <h6 class="modal-title">Activation Code</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-lg-12 col-sm-12 vs-text-pad-r">
              <label class="pure-material-textfield-outlined w-100">
                <input placeholder=" " [(ngModel)]="useractivation.activationcode" name="activationcode"
                  data-name="activationcode" [required]="true" data-prefix="Activation Code" data-type="text">
                <span> Enter Activation Code </span>
              </label>
            </div>
          </div>
          <div class="modal-footer">

            <button role="button" aria-label="makeMeLogin" class="cv3b cast-view-3-btn-1 otp-btn-pad" appValidation
              [form]="ngactivationForm" [validparent]="'form-group'" (click)="submitotp(ngactivationForm)">Submit
              OTP</button>

            <button role="button" aria-label="makeMeLogin" class="cv3b cast-view-3-btn-3 otp-btn-pad"
              (click)="resendotp(ngactivationForm)">Resend OTP</button>

          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- <div style="width: 500px; margin-left: 30%;margin-top: 10px;">
    <form novalidate #mainform="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="FirstName"><strong>First Name</strong></label>
                    <input aria-label="fname" type="text" placeholder="First Name" [(ngModel)]="user.fname" name="fname"
                        data-name="fname" [required]="true" data-prefix="First Name" data-type="text" class="form-control">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="LastName"><strong>Last Name</strong></label>
                    <input aria-label="lanme" type="text" placeholder="Last Name" [(ngModel)]="user.lname" name="lname"
                        data-name="lname" [required]="true" data-prefix="Last Name" data-type="text" class="form-control">
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="Email"><strong>Email Addrress</strong></label>
                    <input aria-label="Email" type="text" placeholder="Email" [(ngModel)]="user.email" name="Email"
                        data-name="Email" [required]="true" data-prefix="Email" data-type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="Password"><strong>Password</strong></label>
                    <input aria-label="Password" type="password" placeholder="Password" [(ngModel)]="user.password" name="Password"
                        data-name="Password" [required]="true" data-prefix="Password" data-type="text" class="form-control">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="Password"><strong>Confirm  Password</strong></label>
                    <input aria-label="RePassword" type="password" placeholder="Confirm  Password" [(ngModel)]="user.repassword" name="rePassword"
                        data-name="RePassword" [required]="true" data-prefix="Confirm  Password" data-type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="Mobile"><strong>Mobile No</strong></label>
                    <input aria-label="Mobile" type="text" placeholder="Mobile" [(ngModel)]="user.mobile" name="Mobile"
                        data-name="Mobile" [required]="true" data-prefix="Mobile" data-type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>Packages</strong></label>
                    <select aria-label="choosecountry" name="Packages" data-name="Packages" [(ngModel)]="user.subscriptionplanmasterid"
                        [required]="true" data-prefix="Packages" data-type="select" class="form-control">
                        <option value="">Choose</option>
                        <option *ngFor="let package of packagelist" [value]="package.subscriptionPlanMasterID">
                            {{package.packageName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>Country</strong></label>
                    <select aria-label="choosecountry" name="Country" (change)="getState()" data-name="Country"
                        [(ngModel)]="user.countryMasterID" [required]="true" data-prefix="Country" data-type="select"
                        class="form-control">
                        <option value="">Choose</option>
                        <option *ngFor="let country of countrylist" [value]="country.countryMasterID">
                            {{country.countryName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>State</strong></label>
                    <select aria-label="choosecountry" name="State" data-name="State" [(ngModel)]="user.stateMasterID"
                        [required]="true" data-prefix="State" data-type="select" class="form-control">
                        <option value="">Choose</option>
                        <option *ngFor="let state of statelist" [value]="state.stateMasterID">
                            {{state.stateName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="City"><strong>City</strong></label>
                    <input aria-label="City" type="text" placeholder="City" [(ngModel)]="user.city" name="city"
                        data-name="City" [required]="true" data-prefix="City" data-type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                        [form]="mainform" [validparent]="'form-group'" (click)="saveUser(mainform)">Submit</button>
                </div>
            </div>
        </div>
    </form>

    <div>
        <form novalidate #ngactivationForm="ngForm">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group form-group-lg">
                <label for="id" aria-label="activationcode"><strong>Activation Code</strong></label>
                <input aria-label="activationcode" type="text" placeholder="City" [(ngModel)]="useractivation.activationcode" name="activationcode"
                    data-name="activationcode" [required]="true" data-prefix="Activation Code" data-type="text" class="form-control">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group form-group-lg">
                <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                    [form]="ngactivationForm" [validparent]="'form-group'" (click)="submitotp(ngactivationForm)">Submit OTP</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group form-group-lg">
                <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                    [form]="ngactivationForm" [validparent]="'form-group'" (click)="resendotp(ngactivationForm)">Resend OTP</button>
            </div>
        </div>
    </div>
    </form>
</div>
</div> -->