import { Component, ChangeDetectorRef, AfterContentChecked, OnInit } from '@angular/core';
import { LoaderService } from './services/message/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked,OnInit {
  constructor(
    public lservice: LoaderService,
    private ref: ChangeDetectorRef,
    private router: Router
    ) {

  }
  ngOnInit(): void {
    this.router.events.subscribe((ev) => {
      window.scrollTo(0, 0)
    });
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  title = 'lawsuit';
}
