<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">
  
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <h3>Articles</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">Articles</li>
                    </ol>
                </div>
            </div>
  
        </div>
  
    </div>
    <!-- az-header -->
    <div class="az-content az-content-dashboard lp-content-dashboard pb-0 inner-main inner-about">
        
        <div class="w-100 ">               
          <div class="container">
            <div class="row w-100">
                <!-- <div class="col-lg-6 col-md-6">
                  <div class="feature-mobail-screen">
                    <img src="img/about-stk.png" class="img-fluid">
                  </div>
                </div> -->
                <div class="col-lg-12 col-md-12">
                  <div class="flex-center-1-no">
                    <div class="flex-center-2-no">
                     <style>
                      
                     </style>
                        <div class="list-group">
                          <ul class="link-with-big-text">
                                               
                              <li  *ngFor="let a of articlesList">
                                <a href="{{a.url}}" target="blank">{{a.title}}</a>
                                <p  class="other-p" [innerHTML]="a.subtitle"></p>
                              </li>                          
                              <!-- <li><a href="assets/pdf/article2.pdf" target="blank">Interface between Competition and Intellectual property law</a></li>
                              <li><a href="assets/pdf/IndiaV.Bharat.pdf" target="blank">INDIA V/S BHARAT: A Country with two names</a>
                                <p class="other-p"><span>1</span> Author: Udit Grover 
                                  Mobile Number: 9910585069 
                                  Enrollment No: D/4607/2015.</p>
                                
                                  <p class="other-p">    <span>2</span>  Author: Vikas Bhadana 
                                    Mobile Number: 9911275055 
                                    Enrollment No: P/4312/2010.</p>
                              </li>
                              <li>
                                <a href="assets/pdf/Sutapa Sanyal-converted.pdf" target="blank">
                                  Beyond the veil of Illusionary fantasy lies the trauma of Domestic Violence
                                </a> 
                              </li>
                              <li>
                                <a href="assets/pdf/environment_and_sustainable_development_a_balanced_aproach.pdf" target="blank">
                                Environment and Sustainable Development – a Balanced Aproach
                                Author:
                                </a>   <br>
                                <p class="other-p">Advocate, Nayan Chand Bihani Mobile Number: 9831210790     </p>                        
                              </li>                               -->
                             
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
          </div>

        </div>
          
    </div>
    <app-footer></app-footer>
  </div>