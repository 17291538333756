import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { AuthdataService } from './authdata.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { switchMap, take, filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  constructor(
    private authDataService: AuthdataService,
    private aservice: AuthService,
    private router: Router,
  ) { }
  newWindow: Window = null;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authDataService.isAuthenticated()) {
     
      let token: any = this.authDataService.getToken();
  
      if (!this.authDataService.isTokeExpired()) {
        if (!this.refreshTokenInProgress) {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.aservice.getNewToken(token.refreshtoken).pipe(
            switchMap((authResponse) => {
              this.authDataService.setToken(authResponse.outdata);
              this.refreshTokenInProgress = false;
              token = this.authDataService.getToken();
              this.refreshTokenSubject.next(token.refreshtoken);
             // alert('hi');
              return next.handle(this.injectToken(req));
              
            }),
          );
        } else {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap((res) => {
              return next.handle(this.injectToken(req));
            })
          );
        }
       }
      //  else if (this.authDataService.isTokeExpired() && this.authDataService.isRefreshTokeExpired()) {
      //   // this.authDataService.setToken('');
      //   // this.router.navigate(['/']);
      //   let token: any = this.authDataService.getToken();
      //    this.aservice.logout(token.token, this.authDataService.getUserId()).subscribe(data => {
      //     if (data.flag) {
      //       localStorage.removeItem('token')
      //       localStorage.removeItem('udata')
      //       localStorage.removeItem('sid')
      //       this.router.navigate(['/']);
      //     }
      //   }
      //   );
      //   //this.newWindow.location.href = "/";
      // } 
      else {
      //  alert('hi1');
      let token: any = this.authDataService.getToken();
  
        this.aservice.logout(token.refreshtoken, this.authDataService.getUserId()).subscribe(data => {
          if (data.flag) {
            localStorage.removeItem('token')
            localStorage.removeItem('udata')
            localStorage.removeItem('sid')
            //this.router.navigate(['/']);
            this.router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
          }
        }
        );
      

       // return next.handle(this.injectToken(req));
      }
    } else {
      // return next.handle(req);

        //  alert('hi1');
        let token: any = this.authDataService.getToken();
       
        this.aservice.logout(token.refreshtoken, this.authDataService.getUserId()).subscribe(data => {
          if (data.flag) {
            localStorage.removeItem('token')
            localStorage.removeItem('udata')
            localStorage.removeItem('sid')
            this.router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
            //this.router.navigate(['/']);
          }
        }
        );
      
    }
  }
  injectToken(request: HttpRequest<any>) {
    const token: any = this.authDataService.getToken();
    return request.clone({
      setHeaders: {
        Authorization: `${token.type} ${token.token}`
      }
    });
  }
}//-------------------------
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     if (this.authDataService.isAuthenticated()) {
     
//       let token: any = this.authDataService.getToken();
  
//       if (this.authDataService.isTokeExpired() && !this.authDataService.isRefreshTokeExpired()) {
//         if (!this.refreshTokenInProgress) {
//           this.refreshTokenInProgress = true;
//           this.refreshTokenSubject.next(null);
//           return this.aservice.getNewToken(token.refreshtoken).pipe(
//             switchMap((authResponse) => {
//               this.authDataService.setToken(authResponse.outdata);
//               this.refreshTokenInProgress = false;
//               token = this.authDataService.getToken();
//               this.refreshTokenSubject.next(token.refreshToken);
//              // alert('hi');
//               return next.handle(this.injectToken(req));
              
//             }),
//           );
//         } else {
//           return this.refreshTokenSubject.pipe(
//             filter(result => result !== null),
//             take(1),
//             switchMap((res) => {
//               return next.handle(this.injectToken(req));
//             })
//           );
//         }
//       } else if (this.authDataService.isTokeExpired() && this.authDataService.isRefreshTokeExpired()) {
//         // this.authDataService.setToken('');
//         // this.router.navigate(['/']);
//         let token: any = this.authDataService.getToken();
//          this.aservice.logout(token.token, this.authDataService.getUserId()).subscribe(data => {
//           if (data.flag) {
//             localStorage.removeItem('token')
//             localStorage.removeItem('udata')
//             localStorage.removeItem('sid')
//             this.router.navigate(['/']);
//           }
//         }
//         );
//         //this.newWindow.location.href = "/";
//       } else {
//       //  alert('hi1');

//       if (!this.refreshTokenInProgress) {
//         this.refreshTokenInProgress = true;
//         this.refreshTokenSubject.next(null);
//         return this.aservice.getNewToken(token.refreshtoken).pipe(
//           switchMap((authResponse) => {
//             this.authDataService.setToken(authResponse.outdata);
//             this.refreshTokenInProgress = false;
//             token = this.authDataService.getToken();
//             this.refreshTokenSubject.next(token.refreshToken);
//            // alert('hi');
//             return next.handle(this.injectToken(req));
            
//           }),
//         );
//       } else {
//         return this.refreshTokenSubject.pipe(
//           filter(result => result !== null),
//           take(1),
//           switchMap((res) => {
//             return next.handle(this.injectToken(req));
//           })
//         );
//       }
//        // return next.handle(this.injectToken(req));
//       }
//     } else {
//       return next.handle(req);
//     }
//   }
//   injectToken(request: HttpRequest<any>) {
//     const token: any = this.authDataService.getToken();
//     return request.clone({
//       setHeaders: {
//         Authorization: `${token.type} ${token.token}`
//       }
//     });
//   }
// }

