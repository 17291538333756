import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClearValidationDirective } from '../directives/clear-validation.directive';
import { ValidationDirective } from '../directives/validation.directive';



@NgModule({
  declarations: [
    ValidationDirective,
    ClearValidationDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports:[
    FormsModule,
    ValidationDirective,
    ClearValidationDirective
  ]
})
export class SharedModule { }
