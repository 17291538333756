import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
@Injectable({
  providedIn: 'root'
})
export class LagislationService {

  constructor(
    private httpclient: HttpClient
  ) { }

  public getLagislationAutocomplet(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/lagislation/getlagislationbytitle?title=${searchText}`, {
      headers
    });
  }
  public getlagislationbysection(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/lagislation/getlagislationbysection?title=${searchText}`, {
      headers
    });
  }
  public getlagislationtree(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/lagislation/getlagislationtree?userid=${userid}`, {
      headers
    });
  }
  public getQueryResult(payload,adddocument , userid,frompage): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/ec/getbareacts?adddocument=${adddocument}&userid=${userid}&frompage=${frompage}`, payload, {
      headers
    });
  }
}
