import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');


@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  private httpclientbackend: HttpClient;
  constructor(
    private httpclient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpclientbackend= new HttpClient(handler);
   }
  public getArticles(): Observable<any> {

    return this.httpclientbackend.get<any>(`${environment._apiUrl}api/aricles/getarticles`, {
      headers
    });
  }
  
}
