import { DatePipe } from '@angular/common';
import { Component, Inject, Injectable, InjectionToken, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { CasedetailService } from 'src/app/services/casedetail.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { Title } from '@angular/platform-browser';
declare const $: any;
declare const window: any;
declare interface Window{
  adsbygoogle:any[];
}
declare var adsbygoogle:any[];
@Injectable({

  providedIn: 'root'

})
@Component({
  selector: 'app-casedetail',
  templateUrl: './casedetail.component.html',
  styleUrls: ['./casedetail.component.scss']
})
export class CasedetailComponent implements OnInit {
  ngAfterViewInit() {
    setTimeout(() => {
      try {
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
      } catch (e) {
        //console.error("ads", e);
      }
    }, 900);
  }
  constructor(
    private route: ActivatedRoute,
    private encdec: EncDecService,
    private lservice: LoaderService,
    private aService: AuthService,
    private cservice:CasedetailService,
    private titleService: Title,
    private router: Router,
    
  ) { }

  payload :any={};
  content:any="";
  caseid:"";
  lscitation="";
  qcitation="";
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.cservice.createLinkForCanonicalURL();
    this.route.queryParams.subscribe(params => {
      
      var id = this.encdec.decryptSensitive(params.id);
      this.caseid= id;
      this.lservice.loadme = true;
      this.payload = {
        "track_total_hits": true,
        _source: ['appellant', 'count_overuled', 'respondent', 'hcref', 'cited', 'topic', 'dispose_dt', 'advocate', 'appeal_no', 'appeal_type', 'enote', 'fd', 'judge', 'judge_beanch', 'actshtml', 'count_referred', 'is_refferred', 'courtid', 'fcitation', 'lscitation','content'],
        highlight: {
          "order": "score",
          require_field_match: true,
          pre_tags: [
            '<b class="highlight">'
          ],
          post_tags: [
            '</b>'
          ],
          "fields": {
            "actshtml": {
            }
          },
          type: 'plain',
          "number_of_fragments": 0,
          "fragment_size": 0,
        },
        sort: [
          { dispose_dt: 'desc' },
        ],
        query: {},
        from: 0,
        size: 1
      };
  
      this.payload.query.bool = {};
      this.payload.query.bool.must = [];
      this.payload.query.bool.filter = {
        bool: {
          must: [
  
          ]
        }
      };
      
      this.payload.query.bool.must.push({
        term: {
          id: id
        }
      })
      this.aService.getLatestCase(this.payload, "0", 0).subscribe(data => {
        if (data.flag) {
          
          let response = JSON.parse(data.outdata);
          let hdata: any = {};
          
          if (response.hits.hits[0]) {
            if (response.hits.hits[0].highlight) {
             
              hdata.hcref = (response.hits.hits[0].highlight.hcref) ? response.hits.hits[0].highlight.hcref : response.hits.hits[0]._source.hcref;
              hdata.judge_beanch = (response.hits.hits[0].highlight.judge_beanch) ? response.hits.hits[0].highlight.judge_beanch : response.hits.hits[0]._source.judge_beanch;
              hdata.appellant = (response.hits.hits[0].highlight.appellant) ? response.hits.hits[0].highlight.appellant : response.hits.hits[0]._source.appellant;
              hdata.respondent = (response.hits.hits[0].highlight.respondent) ? response.hits.hits[0].highlight.respondent : response.hits.hits[0]._source.respondent;
              hdata.topic = (response.hits.hits[0].highlight.topic) ? response.hits.hits[0].highlight.topic : response.hits.hits[0]._source.topic;
              hdata.dispose_dt = (response.hits.hits[0].highlight.dispose_dt) ? response.hits.hits[0].highlight.dispose_dt : response.hits.hits[0]._source.dispose_dt;
              hdata.content = (response.hits.hits[0].highlight.content) ? response.hits.hits[0].highlight.content : response.hits.hits[0]._source.content;
              hdata.advocate = (response.hits.hits[0].highlight.advocate) ? response.hits.hits[0].highlight.advocate : response.hits.hits[0]._source.advocate;
              hdata.appeal_no = (response.hits.hits[0].highlight.appeal_no) ? response.hits.hits[0].highlight.appeal_no : response.hits.hits[0]._source.appeal_no;
              hdata.appeal_type = (response.hits.hits[0].highlight.appeal_type) ? response.hits.hits[0].highlight.appeal_type : response.hits.hits[0]._source.appeal_type;
              hdata.enote = (response.hits.hits[0].highlight.enote) ? response.hits.hits[0].highlight.enote : response.hits.hits[0]._source.enote;
              hdata.fd = (response.hits.hits[0].highlight.fd) ? response.hits.hits[0].highlight.fd : response.hits.hits[0]._source.fd;
              hdata.lscitation = (response.hits.hits[0].highlight.lscitation) ? response.hits.hits[0].highlight.lscitation : response.hits.hits[0]._source.lscitation;
              hdata.judge = (response.hits.hits[0].highlight.judge) ? response.hits.hits[0].highlight.judge : response.hits.hits[0]._source.judge;
              hdata.actshtml = (response.hits.hits[0].highlight.actshtml) ? response.hits.hits[0].highlight.actshtml : response.hits.hits[0]._source.actshtml;
              hdata.fcitation = (response.hits.hits[0].highlight.fcitation) ? response.hits.hits[0].highlight.fcitation : response.hits.hits[0]._source.fcitation;;
              
            } else {
              hdata.hcref = response.hits.hits[0]._source.hcref;
              hdata.judge_beanch = response.hits.hits[0]._source.judge_beanch;
              hdata.appellant = response.hits.hits[0]._source.appellant;
              hdata.respondent = response.hits.hits[0]._source.respondent;
              hdata.topic = response.hits.hits[0]._source.topic;
              hdata.dispose_dt = response.hits.hits[0]._source.dispose_dt;
              hdata.content = response.hits.hits[0]._source.content;
              hdata.advocate = response.hits.hits[0]._source.advocate;
              hdata.appeal_no = response.hits.hits[0]._source.appeal_no;
              hdata.appeal_type = response.hits.hits[0]._source.appeal_type;
              hdata.enote = response.hits.hits[0]._source.enote;
              hdata.fd = response.hits.hits[0]._source.fd;
              hdata.lscitation = response.hits.hits[0]._source.lscitation;
              hdata.judge = response.hits.hits[0]._source.judge;
              hdata.actshtml = response.hits.hits[0]._source.actshtml;
              hdata.fcitation = response.hits.hits[0]._source.fcitation;
              
            }
           
            hdata.advocatedata = response.hits.hits[0]._source.advocate;
            hdata.judgedata = response.hits.hits[0]._source.judge;
            hdata.cited = response.hits.hits[0]._source.cited;
            hdata.count_overuled = response.hits.hits[0]._source.count_overuled;
            hdata.count_referred = response.hits.hits[0]._source.count_referred;
            hdata.is_refferred = response.hits.hits[0]._source.is_refferred;
            let pipe = new DatePipe('en-US');
            hdata.dispose_dt = pipe.transform(hdata.dispose_dt, 'dd MMMM yyyy');
            this.setTitle( hdata.appellant + ' Versus ' + hdata.respondent+" , " + hdata.dispose_dt );
          //   if (hdata.is_refferred && hdata.is_refferred === 1) {
          //     this.cService.getOverruleddata(this.encdec.encryptSensitive(response.hits.hits[0]._id)).subscribe(data => {
          //       if (data.flag) {
          //         hdata.overruledhtml = '<p class="overruled" align="center"><font color="#E10000">( Overruled by:  <a href="ContentView.aspx?Filename=' + data.outdatalist[0][0].file_name + '" class="hidden_n">' + data.outdatalist[0][0].Appellants + ' <br> <i>Versus</i><br> ' + data.outdatalist[0][0].Respondents + ',  ' + data.outdatalist[0][0].citation + ' </a> )</font></p>';
          //         this.setData(hdata, response.hits.hits[0]._id);
          //       }
          //     })
          //   } else {
          //     this.setData(hdata, response.hits.hits[0]._id);
          //   }
          // }
          if (hdata.judge_beanch && hdata.judge_beanch.length > 0) {
            this.content += '<p class="highcourt">' + hdata.hcref + ' (<i>' + hdata.judge_beanch + '</i>) </p>';
          } else {
            this.content += '<p class="highcourt">' + hdata.hcref + '</p>';
          }
          this.content += '<p class="judgtitle">' + hdata.appellant + ' <br> <i> Versus </i><br> ' + hdata.respondent + '</p>';
          if (hdata.is_refferred && hdata.is_refferred === 1) {
            this.content += hdata.overruledhtml;
          }
          this.content += '<p class="judgdate"><strong style="font-weight: 700">Date of Decision: </strong> ' + hdata.dispose_dt + '</p>';
          this.content += '<p class="citations"><strong style="font-weight: 700">Citation: </strong> ' + hdata.lscitation + '</p>';
          this.content += '<hr>';
          this.lscitation = hdata.lscitation;
          this.qcitation = this.encdec.encryptSensitive(this.lscitation);
          // if (hdata.judge && hdata.judge.length > 0) {
          //   this.content += `<p class="judges"><strong style="font-weight: 700">Hon'ble Judges: </strong> ${hdata.judge}</p > `;
          // }
          this.content += `<p class="psign"><strong style="font-weight: 700">Start your journey today with a complimentary 3-day trial!  <span> <a href='LoginFullDetails:' class="blink"> Sign up </a></span> now for instant access. </strong></p>`;
          if (hdata.judge && hdata.judge.length > 0) {
            let judge = "";
            let judgelistdata = hdata.judgedata.toString().split(',');
            let judgelist = hdata.judge.toString().split(',');
            for (let index = 0; index < judgelistdata.length; index++) {
              if (judge == "") {
                judge = "<span><a href='judgecase:" + judgelistdata[index] + "' >" + judgelist[index] + "</a>" + '<span>';
              }
              else {
                judge = judge + ", " + "<span><a href='judgecase:" + judgelistdata[index] + "' >" + judgelist[index] + "</a>" + '<span>';
              }
            }
            //${hdata.judge}
            this.content += `<p class="judges"><strong style="font-weight: 700">Hon'ble Judges: </strong> ${judge} </p >`;
          }
          // if (hdata.fcitation && hdata.fcitation.length > 0) {
          //   this.content += '<p class="eqcitations"><strong style="font-weight: 700">Eq. Citations: </strong>' + hdata.fcitation + '</p>';
          // }
          if (hdata.appeal_type && hdata.appeal_type.length > 0) {
            this.content += '<p class="apptype"><strong style="font-weight: 700">Case Type: </strong> ' + hdata.appeal_type + '</p>';
          }
          if (hdata.appeal_no && hdata.appeal_no.length > 0) {
            this.content += '<p class="appealno"><strong style="font-weight: 700">Case No: </strong> ' + hdata.appeal_no + '</p>';
          }
          if (hdata.topic && hdata.topic.length > 0) {
            this.content += '<p class="fd"><strong style="font-weight: 700">Subject: </strong>' + hdata.topic + '</p>';
          }
          if (hdata.enote && hdata.enote.length > 0) {
            this.content += `<p class="hn"><strong style="font-weight: 700">Editor's Note: </strong><br>${hdata.enote}</p > `;
          }
          if (hdata.actshtml && hdata.actshtml.length > 0) {
            this.content += '<p class="acts"><strong style="font-weight: 700">Acts Referred: </strong><br>' + hdata.actshtml + '</p>';
          }
          if (hdata.fd && hdata.fd.length > 0) {
            this.content += '<p class="fd"><strong style="font-weight: 700">Final Decision: </strong> ' + hdata.fd + '</p>';
          }
          if (hdata.advocate && hdata.advocate.length > 0) {
            let advocate = "";
            let advlistdata = hdata.advocatedata.toString().split(',');
            let advlist = hdata.advocate.toString().split(',');
            for (let index = 0; index < advlistdata.length; index++) {
              if (advocate == "") {
                advocate = "<span><a href='advcase:" + advlistdata[index] + "' >" + advlist[index] + "</a>" + '<span>';
              }
              else {
                advocate = advocate + ", " + "<span><a href='advcase:" + advlistdata[index] + "' >" + advlist[index] + "</a>" + '<span>';
              }
            }
      
            this.content += '<p class="advocate"><strong style="font-weight: 700">Advocates: </strong> ' + advocate + '</p>';
          }
          if(hdata.content.length > 0){
            this.content += '<p>' + hdata.content.substring(0,2000) +'...</p>';
          }
          this.setEvents();
          // if (hdata.advocate && hdata.advocate.length > 0) {
          //   this.content += '<p class="advocate"><strong style="font-weight: 700">Advocates: </strong> ' + hdata.advocate + '</p>';
          // }
          // if (hdata.cited && hdata.cited > 0) {
          //   this.content += '<div id="dvExpandLink"><p class="rjud"><strong><a href="#aColspandDv" id="aCaseCited" class="hidden_n">Cases Cited in (+):</a></strong> <b>' + hdata.cited + '</b><br>';
          // }
          // if (hdata.count_referred && hdata.count_referred > 0) {
          //   this.content += '<strong><a href="#aColspandDv" id="aCaseRef" class="hidden_n aCaseRef">Cases Referred in (+):</a></strong> <b>' + hdata.count_referred + '</b><br>';
          // }
          // if (hdata.count_overuled && hdata.count_overuled > 0) {
          //   this.content += '<strong><a  href="#aColspandDv" id="aCaseOver" class="hidden_n">Cases Overruled (+):</a></strong> <b>' + hdata.count_overuled + '</b></p></div>';
          // }
          // this.content += '<div><p class="advocate"><a onclick="ColspandCaseDtl()" id="aColspandDv" href="#" style="display:none;" class="hidden_n"><strong style="font-weight: 700">Hide (-)</strong></a></p></div>';
      
          // this.content += '<div id="dvCaseCitedDtl" class="acrefdetails"></div><hr>';
          // this.content += hdata.content;
           this.lservice.loadme = false;
  
          }
        }
        });
    });
  }

  LoginFullDetails(){
   this.router.navigateByUrl("/?query="+ this.encdec.encryptSensitive(this.lscitation));
  }

  setEvents() {
    $('#divcontent').animate({
      scrollTop: 0
    }, 0);
  
    setTimeout(() => {
      window.scrollTo(0, 0);
      $('#divcontent').find('a').each(function () {
        if($(this).attr('href') != undefined){
          if ($(this).attr('href').indexOf('#aColspandDv') !== -1) {
            $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
            $(this).attr('data-action', 'action');
            $(this).attr('href', 'javascript:void(0)');
          }
        
        if ($(this).attr('href').indexOf('advcase:') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'advcase');
          $(this).attr('href', 'javascript:void(0)');
        }
        if ($(this).attr('href').indexOf('judgecase:') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'judgecase');
          $(this).attr('href', 'javascript:void(0)');
        }
        else if ($(this).attr('href').startsWith('#')) {
          $(this).attr('data-href', $(this).attr('href'));
          $(this).attr('data-action', 'section');
          if ($(this).hasClass('hidden')) {
            $(this).attr('id', $(this).attr('href').replace('#', ''));
          }
          $(this).attr('href', 'javascript:void(0)');
        }
        else if ($(this).attr('href').indexOf('ContentView.aspx') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'action');
          $(this).attr('href', 'javascript:void(0)');
        }
        else if ($(this).attr('href').indexOf('LoginFullDetails:') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'action');
       //   this.router.navigateByUrl("/?query="+ this.encdec.encryptSensitive(this.lscitation));
          $(this).attr('href', 'javascript:void(0)');
        }
        else if ($(this).attr('href').indexOf('_') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'action');
          $(this).attr('href', 'javascript:void(0)');
        }
      }
      }, 200)
    
      $("p.advocate > a").attr('data-href', EncDecService.encryptSensitiveV1('hidecollapse'));;
      $("p.advocate > a").attr('data-action', 'action');
      $("p.advocate > a").hide();
      $('a[data-action="section"]').off('click').on('click', function () {
        // if ($(this).attr('id')) {
        //   $('#baretocase').val($(this).attr('data-href'));
        //   document.getElementById('baretocase').dispatchEvent(new Event('change'));
        // } else {
          $($(this).attr('data-href')).focus();
          var tmpaid = $(this).attr('data-href');
          $('#divcontent').animate({
           scrollTop: $(tmpaid)[0].offsetTop
           //scrollTop: $('#divcontent').scrollTop() + 230
          }, 100);
        //}
      });
      $('a[data-action="action"]').off('click').on('click', function () {
        $('#lno').val($(this).attr('data-href'));
        document.getElementById('lno').dispatchEvent(new Event('change'));
      });
      $('a[data-action="advcase"]').off('click').on('click', function () {
        $('#lno').val($(this).attr('data-href'));
        document.getElementById('lno').dispatchEvent(new Event('change'));
      });
      $('a[data-action="judgecase"]').off('click').on('click', function () {
        $('#lno').val($(this).attr('data-href'));
        document.getElementById('lno').dispatchEvent(new Event('change'));
      }); 
    }, 200)
  }

  getInnerDataByClick() {
   
   
    let lno = this.encdec.decryptSensitiveV1($('#lno').val());
    let ldata = lno.replace('ContentView.aspx?', '');
    let data = ldata.split('#');
    if (data[0].indexOf('Filename=') !== -1) {
      
      let fno = data[0].replace('Filename=', '');
      
     // this.getCaseByID(fno, data[1])
    }

      //this.history[this.history.length - 1].doc = $('#divcontent').html();
    
    else if (data[0].indexOf('id=') !== -1) {
     
      let fno = data[0].replace('id=', '');
     
     // this.getCaseByID(fno, data[1])
    
      //this.history[this.history.length - 1].doc = $('#divcontent').html();
    }
    else if (data[0].indexOf('advcase:') !== -1) {
      let fno = data[0].replace('advcase:', '');
      fno = fno.toString().replace('unsafe:', '');
      //this.advcaseclick.emit({ advocatename: fno, type: 'A' });


      //this.history[this.history.length - 1].doc = $('#divcontent').html();
    }
    else if (data[0].indexOf('judgecase:') !== -1) {
      let fno = data[0].replace('judgecase:', '');
      fno = fno.toString().replace('unsafe:', '');
      //this.advcaseclick.emit({ advocatename: fno, type: 'J' });
    }
    else if (ldata.indexOf('unsafe:LoginFullDetails:') !== -1) {
      this.router.navigateByUrl("/registration");
    } 
    else if (ldata.indexOf('#aColspandDv') !== -1) {
      //this.getCaseCited();
    } else if (ldata.indexOf('hidecollapse') !== -1) {
      $("p.rjud > strong > a").show();
      $("p.rjud > strong + b").show();
      $("p.advocate > a").hide();
      $(".acrefdetails").hide();
      
    } else if (data[0].indexOf('_') !== -1) {
      let lgno = data[0].split('-');
      this.router.navigateByUrl('/bareactdetail?lno=' + EncDecService.encryptSensitiveV1(lgno));
     // this.getActByID(lgno[0], lgno[1])
      
      // this.history[this.history.length - 1].doc = $('#divcontent').html();
    }
  }
}
