import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpclient: HttpClient) { }
  public addInquiry(inquiry): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/contact/addcontactdetails`, inquiry, {
      headers
    });
  }
}

