
<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">
  
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <!-- <h3>Case</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">CaseDetail</li>
                    </ol> -->
                </div>
            </div>
  
        </div>
  
    </div>
    <div class="row">
<div class="col-md-2">
    <ins class="adsbygoogle"
    style="display: block"
    data-ad-client="ca-pub-9432059337219171"
    data-ad-slot="1521209424" data-ad-format="auto"
    data-full-width-responsive="true"
  ></ins>
</div>
<div class="col-md-8">
<div  id="divcontent" class="" [innerHTML]="content">

</div>
</div>
<div class="col-md-2">
    <ins
    class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-9432059337219171"
    data-ad-slot="1521209424" data-ad-format="auto"
    data-full-width-responsive="true"
  ></ins>
</div> 
</div>
<div style="text-align:center; text-size:16px;"><a [routerLink]="['../']" [queryParams]="{query: qcitation}"  style="cursor:pointer;color:blue;font-weight: bold;text-align: center;"
  >Please login to view the Full Judgment!</a></div><br>
<div class="text-center">
    <ins
      class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-9432059337219171"
      data-ad-slot="6083997313"  data-ad-format="auto"
      data-full-width-responsive="true" 
    ></ins>
  </div>
<input type="hidden" id='lno' (change)="getInnerDataByClick()">

<!-- <button type="button"  class="cv3b cast-view-3-btn-3" (click)="LoginFullDetails()" >Please login to view the Full Judgment!</button> -->
<br> 
<app-footer></app-footer>
</div>