import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { ToastService } from 'src/app/services/message/toast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/message/loader.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { CaselawService } from 'src/app/admin/services/caselaw.service';
import { WelcomeService } from 'src/app/admin/services/welcome.service';
// import { $ } from 'protractor';

declare const $: any;
declare const setNavigator: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: any = {};
  forgotuser: any = {};
  olduser: any = {};
  useractivation: any = {};
  usersendotp: any = {};
  confirmotp = "";
  payload: any = {};
  prepayload: any = {};
  supremecasedata = [];
  useremailaddress: "";
  querypayload: any = {};
  returnURL = null;
  caseid="";
  keywords="";
  parentID =0;
  selectedtree = [];
  treedata=[];
  courtids = "";
  lsno="";
  constructor(
    private adatService: AuthdataService,
    private aService: AuthService,
    private mService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    public lservice: LoaderService,
    private encdec: EncDecService,
    private cService: CaselawService,
    private aDataService: AuthdataService,
    private aWelcome: WelcomeService,
  ) { }

  ngOnInit(): void {
    this.lservice.loadme = false;
    this.route.queryParamMap.subscribe(queryParams => {
      this.returnURL = (queryParams.get('returnURL')) ? this.encdec.decryptSensitive(queryParams.get('returnURL').toString()) : null;
      this.caseid = (queryParams.get('query')) ? this.encdec.decryptSensitive(queryParams.get('query').toString()) : null;
      this.lsno = (queryParams.get('lsno')) ? this.encdec.decryptSensitive(queryParams.get('lsno').toString()) : null;
    });
   
    if (localStorage.getItem('token') && (this.caseid =="" || this.caseid == null) &&  (this.lsno =="" || this.lsno == null))  {
      this.router.navigate(['/Lawsuit/welcome']);
    }
    else if(!localStorage.getItem('token') && (this.caseid =="" || this.caseid == null) && (this.lsno =="" || this.lsno == null)){
      this.router.navigate(['/']);
    }
    else if(!localStorage.getItem('token') && (this.caseid != "") && (this.lsno =="" || this.lsno == null)){
      this.RedirectCaseLawList();
    }
    else{
      this.RedirectBareactList();
    }
  
    if (localStorage.getItem('rem')) {
      this.user.remember = localStorage.getItem('rem');
      this.user.username = this.encdec.decryptSensitive(localStorage.getItem('uname'));
      this.user.password = this.encdec.decryptSensitive(localStorage.getItem('pass'));
    } else {
      this.user.remember = false;
      this.user.username = '';
      this.user.password = '';
    }
    setNavigator();
    $("#divconfirmotp").hide();
    this.getsupremecourtdata(1);
  }
  CheckLoginUser(mainform) {
    if (mainform.valid) {
      if (this.user.remember) {
        localStorage.setItem('rem', this.user.remember);
        localStorage.setItem('uname', this.encdec.encryptSensitive(this.user.username));
        localStorage.setItem('pass', this.encdec.encryptSensitive(this.user.password));
      } else {
        localStorage.removeItem('rem');
        localStorage.removeItem('uname');
        localStorage.removeItem('pass');
      }
      this.lservice.loadme = true;
      this.aService.CheckLoginUser(this.user).subscribe(data => {
        if (data.flag) {
          this.adatService.setToken(data.outdata);
          this.adatService.setUserData(data.outdata1[0]);
          this.adatService.setSessionId(data.outdata2);
         // this.mService.generateMessage("SUCCESS", data.message, "Success");
          this.lservice.loadme = false;
          if (this.returnURL) {
            this.router.navigateByUrl(this.returnURL);
          }
          else if (this.caseid)
           {

            this.RedirectCaseLawList();
           // this.router.navigateByUrl('/Lawsuit/casedetailview?query='+this.caseid);
           }
           else if(this.lsno){
              this.RedirectBareactList();
           }
          else {
            this.router.navigate(['/Lawsuit/welcome']);
          }
          this.user = {};
        } else {
          if (data.outdata == 'Your Account is not active.Please Enter Activation Code.') {

            $(".back").css('transform', 'perspective(600px) rotateY(0)')
            $(".front").css('transform', 'perspective(600px) rotateY(-180deg)')
            $("#divconfirmotp").hide();
            $("#divforgotpassword").hide();
            $("#divactivationcode").show();
            this.usersendotp = data.outdata1;
            this.useractivation.userid = this.usersendotp[0].userid;
          }
          this.mService.generateMessage("ERROR", data.outdata, "Failed");
          this.lservice.loadme = false;
        }
      })
    }
  }
 RedirectFooter(){
          $('html, body').animate({
          scrollTop: $("#divmainfooter").offset().top
      }, 2000);
    }
  RedirectCaseLawList(){
    let courtid = [];
    this.aWelcome.getTree(this.aDataService.getUserId(), this.encdec.encryptSensitive('1')).subscribe(data => {
      if (data.flag) {
        let iscourtpending = 0;
        let allunchecked = 0;
        let dummytree = JSON.parse(data.outdatalist[0]);
        this.selectedtree = (data.outdatalist[1] != 'No Data Found') ? JSON.parse(data.outdatalist[1])[0].usercourtids.split(',') : [];
        dummytree.forEach(element => {
          let em: any = {};
          em.id = element.id;
          em.title = element.title;
          em.cc = [];
          element.cc.forEach(elementcc => {
            let cc: any = {
              subid: elementcc.subid,
              subtitle: elementcc.subtitle
            }
            if (this.selectedtree.includes(elementcc.subid.toString())) {
              cc.issubchecked = true;

            } else {
              cc.issubchecked = false;

            }
            em.cc.push(cc)
          });
          this.treedata.push(em);

        });
         
          this.treedata.filter((maincourt) => {
            let setchecked = true;
            maincourt.cc.filter((subcourt) => {
              courtid.push(subcourt.subid);
            });
      
 
        });

    this.prepayload = {
      "track_total_hits": true,
      _source: ['appellant', 'count_overuled', 'is_refferred', 'count_referred', 'actshtml', 'respondent', 'hcref', 'cited', 'topic', 'dispose_dt', 'advocate', 'appeal_no', 'appeal_type', 'enote', 'fd', 'judge', 'judge_beanch', 'courtid', 'fcitation', 'lscitation'],
      highlight: {
        "order": "score",
        pre_tags: [
          '<b class="highlight">'
        ],
        post_tags: [
          '</b>'
        ],
        "fields": {
          "content": {
            "require_field_match": true,
          },
          "respondent": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "appellant": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "topic": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "appeal_type": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "appeal_no": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "fd": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "advocate": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "actshtml": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
          "judge": {
            type: 'plain',
            "number_of_fragments": 0,
            require_field_match: true,
          },
        },

        type: 'plain',
        "number_of_fragments": 2,
        "fragment_size": 150
      },
      sort: [
        { cited: 'desc' },
      ],
      aggs: {
        "court_counts": {
          "terms": {
            "field": "courtname",
            size: 1000,
            "order": { "courtid": "asc" }
          }
          ,
          "aggs": {
            "courtid": {
              "max": {
                "field": "courtid"
              }
            }
          }
        }
      },
      query: {},
      from: 0,
      size:1
    };
    this.prepayload.query.bool = {};
    this.prepayload.query.bool.must = [];
    this.prepayload.query.bool.filter = {
      bool: {
        must: [

        ]
      }
    };

    this.keywords = ""; 
    let ctdata = {
      match_phrase: {}
    }
    var casestring="";
    if(this.caseid)
      {
        
        let dummy = this.caseid.split(' ');
        if(dummy.length > 0){
          if(dummy.length == 3){
              casestring = dummy[1] +dummy[0]+ ' 0 '+ dummy[2];
          }
          else{
            casestring = dummy[1] +dummy[0]+ ' ' + dummy[2] +' '+ dummy[3];
          }
        }
      }
      ctdata.match_phrase =
      {
        citation: {
          query: casestring,
          slop: 0
        }
      }
    
      this.keywords += " CITATION : " + this.caseid
      this.prepayload.query.bool.filter.bool.must.push(ctdata);

      if (courtid.length > 0) {
        let courtdata = {
          terms: {
            courtid: courtid
          }
        }

        this.prepayload.query.bool.filter.bool.must.push(courtdata);
      }

      this.querypayload.refineKeywords = "";
      this.querypayload.keywords = "";
      this.querypayload.keywords += (this.keywords.length > 0) ? this.keywords : 'No Keywords specified';
      this.querypayload.query = JSON.stringify(this.prepayload);
      this.querypayload.typeofSearch = 1;
      this.querypayload.SearchType = '';
      this.querypayload.userID = this.aDataService.getUserId();
      this.querypayload.sessionID = 1;
      this.querypayload.parentID = this.parentID;

      this.lservice.loadme = true;
      this.cService.saveQuery(this.querypayload).subscribe(data1 => {
        if (data1.flag) {
          this.cService.getQueryResult(this.prepayload, "0", this.aDataService.getUserId()).subscribe(data => {
            if (data.flag) {
              this.parentID = (this.parentID > 0) ? this.parentID : parseInt(data1.outdata);
              this.router.navigateByUrl('/Lawsuit/searchresults?query=' + this.encdec.encryptSensitive(JSON.stringify(this.prepayload)) + '&keywords=' + this.encdec.encryptSensitive(this.querypayload.keywords) + '&parentID=' + this.encdec.encryptSensitive(this.parentID));        
            } 
          });
        } 
      });
    }
  
  });
}

RedirectBareactList(){
  let courtid = [];
  this.aWelcome.getTree(this.aDataService.getUserId(), this.encdec.encryptSensitive('1')).subscribe(data => {
    if (data.flag) {
      let iscourtpending = 0;
      let allunchecked = 0;
      let dummytree = JSON.parse(data.outdatalist[0]);
      this.selectedtree = (data.outdatalist[1] != 'No Data Found') ? JSON.parse(data.outdatalist[1])[0].usercourtids.split(',') : [];
      dummytree.forEach(element => {
        let em: any = {};
        em.id = element.id;
        em.title = element.title;
        em.cc = [];
        element.cc.forEach(elementcc => {
          let cc: any = {
            subid: elementcc.subid,
            subtitle: elementcc.subtitle
          }
          if (this.selectedtree.includes(elementcc.subid.toString())) {
            cc.issubchecked = true;

          } else {
            cc.issubchecked = false;

          }
          em.cc.push(cc)
        });
        this.treedata.push(em);

      });
       
        this.treedata.filter((maincourt) => {
          let setchecked = true;
          maincourt.cc.filter((subcourt) => {
            courtid.push(subcourt.subid);
          });
    

      });
  let pageIndex = 1;
  this.payload = {
    "track_total_hits": true,
    _source: ['title', 'lno', 'lyear','id'],
    highlight: {
      "order": "score",
      require_field_match: true,
      boundary_scanner:'sentence',
      boundary_max_scan:300,
      "number_of_fragments": 2,
      "fragment_size": 150,
      type: 'plain',
      pre_tags: [
        '<b class="highlight">'
      ],
      post_tags: [
        '</b>'
      ],
      "fields": {
        "content": {

        },
        "title": {
          type: 'plain',
          "number_of_fragments": 0
        }
      },
    },
    sort: [
      '_score',
      'courtid'
    ],
    aggs: {
      "court_counts": {
        "terms": {
          "field": "courtname",
          size: 5000
        }
      }
    },
    query: {},
    from: ((pageIndex - 1) * 20),
    size: 20
  };
  this.payload.query.bool = {};
  this.payload.query.bool.must = [];
  this.payload.query.bool.filter = {
    bool: {
      must: [

      ]
    }
  };

  if ((this.lsno && this.lsno.length > 0)) {
    // let cdata = {
    //   match_phrase: {
    //     id: {
    //       query: this.lsno,
    //       slop: 0
    //     }
    //   }

      this.payload.query.bool.must.push({
        term: {
          id: this.lsno
        }
      })
    }
   
    //this.payload.query.bool.filter.bool.must.push(cdata);
  
  this.router.navigateByUrl('/Lawsuit/bareactslist?query=' + this.encdec.encryptSensitive(JSON.stringify(this.payload)));
}
  });
}

  openpasswordforgot() {
    $(".back")
      .css('transform', 'perspective(600px) rotateY(0)')
    $(".front")
      .css('transform', 'perspective(600px) rotateY(-180deg)')
    $("#divconfirmotp").hide();
    $("#divconfirmsendotp").hide();
    $("#divupdatepassword").hide();
    $("#divforgotpassword").show();
    $("#divactivationcode").hide();
    // $("#divlogin").hide();
    // $("#divforgotpassword").show();
    //var options = {};
    // $("#divforgotpassword").effect( 'blind', options, 500 );
  }
  backtologin() {
    $(".front")
      .css('transform', 'perspective(600px) rotateY(0deg)')
    $(".back")
      .css('transform', 'perspective(600px) rotateY(180deg)')

    // $("#divlogin").show();
    // $("#divforgotpassword").hide();
  }
  submitotp(ngSubActivationForm) {
    if (ngSubActivationForm.valid) {

      this.aService.submitotp(this.useractivation).subscribe(data => {
        if (data.flag) {
          this.mService.generateMessage("SUCCESS", data.message, "Success");
          $(".front").css('transform', 'perspective(600px) rotateY(0deg)')
          $(".back").css('transform', 'perspective(600px) rotateY(180deg)')
          this.user = {};
        } else {
          this.mService.generateMessage("ERROR", data.message, "Failed");
        }
      })
    }
  }
  resendotp(ngSubActivationForm) {

    this.aService.resendotp(this.usersendotp[0]).subscribe(data => {
      if (data.flag) {
        this.mService.generateMessage("SUCCESS", data.message, "Success");
        this.user = {};
      } else {
        this.mService.generateMessage("ERROR", data.message, "Failed");
      }
    })
  }
  sendforgotpasswordotp(formForgotpwd) {

    if (formForgotpwd.valid) {
      let a = {
        toEmail: this.useremailaddress
      }
      this.aService.forgotpwdsendotp(a).subscribe(data => {
        if (data.flag) {
          $("#divconfirmotp").show();
          $("#divconfirmsendotp").show();
          $("#divupdatepassword").hide();
          $("#divforgotpassword").hide();
          $("#divactivationcode").hide();
          // $(".backotp").css('transform', 'perspective(600px) rotateY(0)')
          // $(".back").css('transform', 'perspective(600px) rotateY(180deg)')
          this.mService.generateMessage("SUCCESS", data.message, "Success");
          this.forgotuser = data.outdata[0];
        } else {
          this.mService.generateMessage("ERROR", data.message, "Failed");
        }
      })
    }
    else {
      this.mService.generateMessage("ERROR", "", "Failed");
    }
  }

  ResendOTPpassword(formConfirmOTP){
    let a = {
        toEmail: this.useremailaddress
      }
      this.aService.forgotpwdsendotp(a).subscribe(data => {
        if (data.flag) {
          $("#divconfirmotp").show();
          $("#divconfirmsendotp").show();
          $("#divupdatepassword").hide();
          $("#divforgotpassword").hide();
          $("#divactivationcode").hide();
          // $(".backotp").css('transform', 'perspective(600px) rotateY(0)')
          // $(".back").css('transform', 'perspective(600px) rotateY(180deg)')
          this.mService.generateMessage("SUCCESS", data.message, "Success");
          this.forgotuser = data.outdata[0];
        } else {
          this.mService.generateMessage("ERROR", data.message, "Failed");
        }
      })
  }

  updateconfimotpuser(formForgotpwd) {

    if (formForgotpwd.valid) {
      let a = {
        confirmotp: this.confirmotp,
        userid: this.forgotuser.userid
      }
      this.aService.updateconfimotpuser(a).subscribe(data => {
        if (data.flag) {
          $("#divconfirmsendotp").hide();
          $("#divupdatepassword").show();
          $("#divforgotpassword").hide();
          this.mService.generateMessage("SUCCESS", data.message, "Success");

        } else {
          this.mService.generateMessage("ERROR", data.message, "Failed");
        }
      })
    }
    else {
      this.mService.generateMessage("ERROR", "", "Failed");
    }
  }
  UpdateUserPassword(formForgotpwd) {

    if (formForgotpwd.valid) {
      let a = {
        password: this.olduser.password,
        userid: this.forgotuser.userid
      }
      this.aService.usernewpassowrdsetup(a).subscribe(data => {
        if (data.flag) {
          $("#divconfirmsendotp").hide();
          $("#divupdatepassword").hide();
          $(".front").css('transform', 'perspective(600px) rotateY(0deg)')
          $(".back").css('transform', 'perspective(600px) rotateY(180deg)')
          this.mService.generateMessage("SUCCESS", data.message, "Success");
        } else {
          this.mService.generateMessage("ERROR", data.message, "Failed");
        }
      })
    }

  }


  getsupremecourtdata(type) {
    // this.lservice.loadme = true;
    this.payload = {
      "track_total_hits": true,
      _source: ['appellant', 'count_overuled', 'respondent', 'hcref', 'cited', 'topic', 'dispose_dt', 'advocate', 'appeal_no', 'appeal_type', 'enote', 'fd', 'judge', 'judge_beanch', 'actshtml', 'count_referred', 'is_refferred', 'courtid', 'fcitation', 'lscitation'],
      highlight: {
        "order": "score",
        require_field_match: true,
        pre_tags: [
          '<b class="highlight">'
        ],
        post_tags: [
          '</b>'
        ],
        "fields": {
          "actshtml": {
          }
        },
        type: 'plain',
        "number_of_fragments": 0,
        "fragment_size": 0,
      },
      sort: [
        '_score',
        { dispose_dt: 'desc' },
      ],
      query: {},
      from: 0,
      size: 10
    };
    this.payload.query.bool = {};
    this.payload.query.bool.must = [];
    if (type == 1) {
      this.payload.query.bool.filter = {
        bool: {
          must: {
            "term": {
              "courtid": 1
            }
          }
        }
      };
    }
    else {
      this.payload.query.bool.filter = {
        bool: {
          must_not: {
            "term": {
              "courtid": 1
            }
          }
        }
      };
    }
    this.aService.getLatestCase(this.payload, "0", 0).subscribe(data => {
      if (data.flag) {
        this.supremecasedata = JSON.parse(data.outdata).hits.hits;
         this.lservice.loadme = false;

      }
    });
  }
  public trackByIndex(index: number) {
    return index;
  }
}
