
<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">
  
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <!-- <h3>Case</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">CaseDetail</li>
                    </ol> -->
                </div>
            </div>
  
        </div>
  
    </div>
<div id="divcontent" class="" [innerHTML]="content" style="padding-left: 10px;padding-right: 10px;">

</div> 
<!-- <input type="hidden" id='lno' (change)="getInnerDataByClick()"> -->
<div style="text-align:center"><a [routerLink]="['../']" [queryParams]="{lsno: lscitation}"  style="cursor:pointer;color:blue;font-weight: bold;text-align: center;"
  >Please login to view the Full Judgment!</a></div>
<!-- <button type="button"  class="cv3b cast-view-3-btn-3" (click)="LoginFullDetails()" >Please login to view the Full Judgment!</button> -->
<br> 
<app-footer></app-footer>
</div>