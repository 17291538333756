import { Component, OnInit } from '@angular/core';
import { PackageService } from 'src/app/services/package.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/message/toast.service';
import { LoaderService } from 'src/app/services/message/loader.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  constructor(private pService: PackageService, private router: Router, private mService: ToastService, public lservice: LoaderService) { }
  packageList = [];
  ngOnInit(): void {
    this.pService.getPackages().subscribe(data => {
      if (data.flag) {
        this.packageList = data.outdatalist[0];
      } else {
        this.mService.generateMessage("ERROR", data.message, "Failed");
      }
    })
  }
  subscribePackages(packageid,amount) {
    this.lservice.loadme = true;
    let objpackage = {
      "amount": amount,
      "redirect_url": "http://localhost:4200",
      "userid": 0,
      "packageId": packageid
    }
    this.pService.subscribePackge(objpackage).subscribe(data => {
      if (data.flag) {
        let url: any = data.outdata.payment_options.payment_url;
        window.location.href = url;
      } else {
        this.lservice.loadme = false;
        this.mService.generateMessage("ERROR", data.message, "Failed");
      }
    })
  }
  Openregistraion(tmppackage){
    window.location.href = "/registration";
  }
}
