<!-- {{packageList | json}}
<div class="row m-2 text-center">
    <div class="col-md-3 mb-2" *ngFor="let package of packageList">
        <div class="card h-100">
            <div class="card-header" style="height: 75px;font-weight: 600;">
                {{package.packageName}}
            </div>
            <div class="card-body">
                <h5 class="card-title"><span class="text-primary">RS.</span>{{package.oneTimeRate}}</h5>
                <p class="card-text" style="height: 100px;max-height: 200px;">{{package.packageDesc}}</p>
                <a href="javascript:void(0)" class="btn btn-primary">Order Now</a><br><br>
                <div style="margin-bottom: 0;">
                    <strong>ANNUAL CHARGES</strong>
                    <p class="card-text text-primary"><b>RS. {{package.renewAmt}} /-</b></p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">

        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <h3>Packages</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">Packages</li>
                    </ol>
                </div>
            </div>

        </div>

    </div>
    <!-- az-header -->
    <div class="az-content az-content-dashboard pb-0">

        <div class="container">
            <div class="az-content-body col-sm-12">
                <div class="row w-100">
                    <div class="col-lg-12 col-md-12">
                        <div class="flex-center-1">
                            <div class="flex-center-2 text-center">
                                <h4 class="pb-2">Lawsuit offers a range of flexible Subscription options for Attorneys,
                                    Law Firms, Colleges and also Students.</h4>
                                <p>Subscriber will have to pay initial Subscription Amount for First time which will
                                    have a validity period of one year. And then after every year, he will have to Renew
                                    Subscription Account within 30 days of Expiry period. Account will be renewed for
                                    one year and after every year, account will have to be Renewed. If the customer
                                    fails to renew the Account within the stipulated time period then he will have to
                                    start from Subscription Amount, which will be more than renew amount in any case.
                                </p>
                                <h3 class="inner-tl pt-4">Package Details of <span> Lawsuit - The Unique Case Finder<sup>&reg;</sup>
                                    </span> Web Edition</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4 package-main-admin">

                    <div class="col-md-3 col-sm-6 package-box-main" *ngFor="let package of packageList">
                        <div class="package-box">
                            <div class="package-box-header">
                                <div class="blue-bg-top">
                                    <h5> Package {{package.priority}}</h5>
                                </div>

                                <!-- <h2><span>RS.</span> {{package.oneTimeRate | currency:'INR'}}</h2> -->
                                <!-- <p class="pricing-details5"><a href="javascript:void(0)">Pricing Details</a></p> -->
                            </div>
                            <div class="package-box-content">
                                <h5 style="color: #f54408;">{{package.packageName}}</h5>
                                <p> <b>Coverage Details: </b> {{package.packageDesc}}</p>
                            </div>
                            <div class="package-box-footer">
                              
                                <p class="annual-p"><b>Annual Subscription</b> <span> ₹{{package.renewAmt | number}} 
                                        /-</span> </p>
                                        <div class="serch-btn-rt_des_left_mobail">
                                            <!-- ="subscribePackages(0,package.oneTimeRate)" -->
                                            <button type="button" class="form-btn yello-btn" (click)="Openregistraion(package)">Free Trial</button>
                                        </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <app-footer></app-footer>
</div>