import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { ToastService } from 'src/app/services/message/toast.service';
import { Router } from '@angular/router';
declare const $: any;
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  constructor(
    private aService: AuthService,
    private adatService: AuthdataService,
    private mService: ToastService,
    private router: Router
  ) { }
  user: any = {
    subscriptionplanmasterid: "27",
    countryMasterID: "5",
    stateMasterID: "",
    ischeckterm: true,
    emailPattern : "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$",
  };
  validpass = true;
  useractivation: any = {};
  totalusercount = 0;
  totalbareactcount = 0;
  totalcasecount = 0;
  countrylist = [];
  packagelist = [];
  statelist = [];
  ngOnInit(): void {
    this.getTotalCount();
    this.aService.getDropDowns().subscribe(data => {
      if (data.flag) {
        this.countrylist = data.outdatalist[0];
        this.packagelist = data.outdatalist[1];
        // alert(data.outdata);
        this.user.countryName = data.outdata;
        this.getState();
        // data= this.countrylist.filter(e=>e.name===this.user.countryName);
        // data[0].id
      } else {

      }
    })
  }
  getState() {
    this.aService.getStatebyCountyID(this.user.countryMasterID).subscribe(data => {
      if (data.flag) {
        this.statelist = data.outdatalist[0];
      } else {

      }
    })
  }

  getTotalCount() {
    this.aService.getTotalCount().subscribe(data => {
      if (data.flag) {
        this.totalusercount = data.outdatalist[0][0].TotalUsers;
        this.totalcasecount = data.outdatalist[1][0].TotalCases;
        this.totalbareactcount = data.outdatalist[2][0].Totalbareacts;


        $(".num").numScroll({
          number: this.totalusercount,
          'time': 2000
        });

        $(".num1").numScroll({
          number: this.totalcasecount
        });

        $(".num2").numScroll({
          number: this.totalbareactcount
        });

        //setTimeout(() => {
        // $('.counter').rollNumber({
        //   speed: 200

        // })

        // var $diy = $('.number-diy .data');
        //   $diy.rollNumber({
        //     number: $diy[0].dataset.number, 
        //     speed: 500, 
        //     // interval: 200,
        //     // rooms: 9,
        //     // //space: 110,
        //     // symbol: ',', 
        //     fontStyle: {
        //       // 'font-size': 102,
        //       // 'font-family': 'LetsgoDigital',
        //     }
        //   })
        // }, 200);
      }
      else {

      }
    })
  }
  saveUser(mainform) {

    if (this.user.password === this.user.repassword) {
      if (mainform.valid) {
        if (this.user.ischeckterm) {
          this.user.countryName = this.countrylist.find(x => x.countryMasterID === parseInt(this.user.countryMasterID)).countryName;
          this.aService.registerUser(this.user).subscribe(data => {
            if (data.flag) {
              if (data.outdata > 0) {
                $("#registration").modal('show');
                this.user.userid = data.outdata;

                //  alert(data.message);
                this.mService.generateMessage("SUCCESS", data.message, "Success");
              }
            } else {
              this.mService.generateMessage("ERROR", data.message, "Failed");
            }
          })
        }
        else {
          this.mService.generateMessage("ERROR", 'Please check terms and condition', "Verification");

        }
      }
    } else {
      this.mService.generateMessage("ERROR", 'password does not match with confirm password', "Verification");

    }

  }
  submitotp(ngactivationForm) {
    if (ngactivationForm.valid) {
      this.useractivation.userid = this.user.userid;
      this.aService.submitotp(this.useractivation).subscribe(data => {
        if (data.flag) {
          $("#registration").modal('hide');
          this.mService.generateMessage("SUCCESS", data.message, "Success");
          this.user = {};
          // this.adatService.setToken(data.outdata);
          // this.adatService.setUserData(data.outdata1[0]);
          // this.adatService.setSessionId(data.outdata2);
          this.router.navigate(['/']);
        } else {
          this.mService.generateMessage("ERROR", data.message, "Failed");
        }
      })
    }
  }
  resendotp(ngactivationForm) {
    this.aService.resendotp(this.user).subscribe(data => {
      if (data.flag) {
        // alert(data.message);
        this.mService.generateMessage("SUCCESS", data.message, "Success");
        this.user = {};
      } else {
        this.mService.generateMessage("ERROR", data.message, "Failed");
      }
    })

  }
}
