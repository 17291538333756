import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthdataService } from 'src/app/services/authdata.service';
//import { Location } from '@angular/common';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(
    private router: Router,
    private aDataService: AuthdataService) { }
  totalcases = 0;
  reminingdays = 0;
  Username = "";
  ngOnInit(): void {

    let userdata = this.aDataService.getUserData();
    this.Username = userdata.username;
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (userdata.daysremaining !== null && userdata.daysremaining !== '') {
          this.reminingdays = parseInt(userdata.daysremaining);
        } else {
          this.reminingdays = 0;
        }
      }
      window.scrollTo(0, 0)
    });
  }
}
