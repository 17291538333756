import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LagislationService } from 'src/app/admin/services/lagislation.service';
import { AuthService } from 'src/app/services/auth.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { CasedetailService } from 'src/app/services/casedetail.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { LoaderService } from 'src/app/services/message/loader.service';
declare const $: any;
declare const window: any;
@Component({
  selector: 'app-bareactdetail',
  templateUrl: './bareactdetail.component.html',
  styleUrls: ['./bareactdetail.component.scss']
})
export class BareactdetailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private encdec: EncDecService,
    private lservice: LoaderService,
    private aService: AuthService,
    private cservice:CasedetailService,
    private titleService: Title,
    private router: Router,
    private lgService: LagislationService,
    private aDataService: AuthdataService,
    
  ) { }

  payload :any={};

  content:any="";
  caseid:"";
  lscitation="";
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.cservice.createLinkForCanonicalURL();
    this.route.queryParams.subscribe(params => {
 
      var id = this.encdec.decryptSensitiveV1(params.lno);
     
      let lgno = id.split(',');
      this.lscitation = this.encdec.encryptSensitive(lgno[0]);
      this.lservice.loadme = true;
    this.payload = {
      query: {
        bool: {
          must: []
        }
      }
    };
    this.payload.query.bool.must.push({
      term: {
        _id: lgno[0]
      }
    })
    this.payload.size = 1;
    this.payload._source = ['content', 'title', 'lno']
    this.aService.getQueryResult(this.payload, "0", 'welcome').subscribe(data => {
      if (data.flag) {
        let response = JSON.parse(data.outdata);
        if (response.hits.total.value > 0) {
        //  this.documentcontentoriginal = response.hits.hits[0]._source.content;
          if (response.hits.hits[0].highlight) {
            this.content = response.hits.hits[0].highlight.content.substring(0,2000) ;
          } else {
            this.content = response.hits.hits[0]._source.content.substring(0,2000);}
        //  this.documentcontentid = response.hits.hits[0]._id;
        } else {
          this.content = 'Content not available';
        }
        
        $('#divcontent').animate({
          scrollTop: 0
        }, 0);
        this.setEvents();
        // $('#modalcontent-view').modal('show');
        this.lservice.loadme = false;
        // if (section && section.length > 0 && this.content !== 'Content not available' && $('#s' + section + '')) {
        //   setTimeout(() => {
        //     document.getElementById('s' + section).focus()
        //     // $('#divcontent').animate({
        //     //   scrollTop: $('#s' + section + '').offset().top
        //     // }, 100);
        //   }, 250);

        // }
      } else {
        
        this.lservice.loadme = false;
      }
    });
  });
  }
  LoginFullDetails(){
    this.router.navigateByUrl("/?query="+ this.encdec.encryptSensitive(this.lscitation));
   }

   setEvents() {
    $('#divcontent').animate({
      scrollTop: 0
    }, 0);
  
    setTimeout(() => {
      window.scrollTo(0, 0);
      $('#divcontent').find('a').each(function () {
        if($(this).attr('href') != undefined){
          if ($(this).attr('href').indexOf('#aColspandDv') !== -1) {
            $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
            $(this).attr('data-action', 'action');
            $(this).attr('href', 'javascript:void(0)');
          }
        
        if ($(this).attr('href').indexOf('advcase:') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'advcase');
          $(this).attr('href', 'javascript:void(0)');
        }
        if ($(this).attr('href').indexOf('judgecase:') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'judgecase');
          $(this).attr('href', 'javascript:void(0)');
        }
        else if ($(this).attr('href').startsWith('#')) {
          $(this).attr('data-href', 'javascript:void(0)');
          // $(this).attr('data-action', 'section');
          // if ($(this).hasClass('hidden')) {
          //   $(this).attr('id', $(this).attr('href').replace('#', ''));
          // }
          $(this).attr('href', 'javascript:void(0)');
        }
        else if ($(this).attr('href').indexOf('ContentView.aspx') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'action');
          $(this).attr('href', 'javascript:void(0)');
        }
        else if ($(this).attr('href').indexOf('_') !== -1) {
          $(this).attr('data-href', EncDecService.encryptSensitiveV1($(this).attr('href')));
          $(this).attr('data-action', 'action');
          $(this).attr('href', 'javascript:void(0)');
        }
      }
      }, 200)
    
      $("p.advocate > a").attr('data-href', EncDecService.encryptSensitiveV1('hidecollapse'));;
      $("p.advocate > a").attr('data-action', 'action');
      $("p.advocate > a").hide();
      $('a[data-action="section"]').off('click').on('click', function () {
        // if ($(this).attr('id')) {
        //   $('#baretocase').val($(this).attr('data-href'));
        //   document.getElementById('baretocase').dispatchEvent(new Event('change'));
        // } else {
          $($(this).attr('data-href')).focus();
          var tmpaid = $(this).attr('data-href');
          $('#divcontent').animate({
           scrollTop: $(tmpaid)[0].offsetTop
           //scrollTop: $('#divcontent').scrollTop() + 230
          }, 100);
        //}
      });
      $('a[data-action="action"]').off('click').on('click', function () {
        $('#lno').val($(this).attr('data-href'));
        document.getElementById('lno').dispatchEvent(new Event('change'));
      });
      $('a[data-action="advcase"]').off('click').on('click', function () {
        $('#lno').val($(this).attr('data-href'));
        document.getElementById('lno').dispatchEvent(new Event('change'));
      });
      $('a[data-action="judgecase"]').off('click').on('click', function () {
        $('#lno').val($(this).attr('data-href'));
        document.getElementById('lno').dispatchEvent(new Event('change'));
      }); 
    }, 200)
  }
}
