<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">
  
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <h3>Feature</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">Feature</li>
                    </ol>
                </div>
            </div>
  
        </div>
  
    </div>
    <!-- az-header -->
    <div class="az-content az-content-dashboard lp-content-dashboard pb-0 inner-main">
        <div class="inner-content feature-top-block">
            <div class="container">
                <div class="w-100 ">
                   <div class="row">
                     <div class="col-sm-8">
                       <div class="">
                          <p class="feature-top-p">We strive every moment to improve the features as well as service which we offers... our goal is to provide better quality of service, which is a process of ongoing improvements, which is used for customer satisfaction. We value our customers and wish to help them in a way which can profit their interest. Once you become a part of our family, we have your interest as ours.</p>
                          <div class="list-group">
                          <ul>
                            <li>  Greater coverage of world wide case law and legislation with amendments in single searching.</li>
                            <li>Elegant full text search in addition to Boolean, advanced as well as equivalent citations. </li>
                            <li>Customized result view which can be sorted on any criteria as per your relevance.</li>
                            <li>Options to print and download customized results & documents.</li>
                            <li>Navigate to any hyperlinks of citations, documents or paragraph mentioned in Judgments.</li>
                            <li>Get alerts on set criteria on any new addition of cases.</li>
                          </ul>
                        </div>
                       </div>
                     </div>
                     <div class="col-sm-4"></div>
                   </div>
                     
                </div>
                           
            </div>            
        </div>
        <div class="w-100 inner-gray-block-color">               
          <div class="container">
            <div class="row w-100">
                <div class="col-lg-4 col-md-3">
                  <div class="feature-mobail-screen">
                    <img src="assets/img/feature-mobail-screen.png" class="img-fluid">
                  </div>
                </div>
                <div class="col-lg-8 col-md-9">
                  <div class="flex-center-1">
                    <div class="flex-center-2">
                      <h3 class="inner-tl"> Additional <span>Features:</span></h3>
                        <div class="list-group">
                          <ul>
                            <li>Access worldwide legal library from any corner of the world.</li>
                            <li>Daily Updates of worldwide case law and legislation with amendments is maintained.</li>
                            <li>It will give the best performance even in dialup connection.</li>
                            <li>It can be also accessed from WAP enabled mobile phones.</li>
                            <li>Connect to Legal Minds: Virtual legal community including blogs, mailing list archives  and message boards.</li>
                            <li>Career Center: The most complete online legal career center, with law firm salary charts, legal job listings, personalized legal job placement services and legal discussion forums.</li>
                            <li>Newsletters and Opinion Summaries: Legal news update for 24 hours and range of legal topics. Email Newsletters, Daily Opinion Summaries, Legal Headlines and Topical Newsletters.</li>
                            <li>It provides a platform for Lawyers as their detailed profile will be online and will create a link   between Corporate Firm, Public and also with outside world.</li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>           
        </div>
        <div class="w-100 inner-gray-block-color bg-shep dw-767">               
          <div class="container">
            <div class="row w-100">
              <div class="col-md-5 order-sm-2">
                <div class="feature-mobail-screen">
                  <img src="assets/img/feature-computer-screen.png" class="img-fluid">
                </div>
              </div>                
                <div class="col-md-7 order-sm-1">
                  <div class="flex-center-1">
                    <div class="flex-center-2">
                      <h3 class="inner-tl">  Lawsuit on your <span> Desktop:</span></h3>
                        <div class="list-group">
                          <ul>
                            <li>Lawsuit on your desktop is same as desktop application but it can access data online. <br>                              So you need not require any free space on your hard disc.</li>
                            <li>Access worldwide legal library from your desktop / laptop that can be accessed on any platform.</li>
                            <li>Access to daily updates which will be updated on daily bases.</li>
                            <li>Lawsuit is also provide offline desktop application with full functionality which is run on your <br>
                              desktop or laptop without internet connection and also it will be update day to day.</li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
                
            </div>
          </div>           
        </div> 
        <div class="w-100 ">               
          <div class="container">
            <div class="row w-100">
              <div class="col-md-5">
                <div class="feature-mobail-screen">
                  <img src="assets/img/feature-office-m.png" class="img-fluid">
                </div>
              </div>                
                <div class="col-md-7">
                  <div class="flex-center-1">
                    <div class="flex-center-2">
                      <h3 class="inner-tl"> Office <span>  Management:</span></h3>
                        <div class="list-group">
                          <p class="mt-2 mb-0 gray-text">This software provides case management system, case tracking, calendar, accounting and legal time billing for lawyers and law firm’s staff. This legal portal does not lead only legal professionals but also public can also use the services. They can come across information such as:</p>
                          <ul>
                            <li> Lawsuit on your desktop is same as desktop application but it can access data online.  
                              <br> So you need not require any free space on your hard disc.</li>
                            <li>Access worldwide legal library from your desktop / laptop that can be accessed on any platform.</li>
                            <li> Access to daily updates which will be updated on daily bases.</li>
                            <li> Lawsuit is also provide offline desktop application with full functionality which is run on your 
                              <br>
                              desktop or laptop without internet connection and also it will be update day to day.</li>
                            
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
                
            </div>
          </div>           
        </div>  
    </div>
    <app-footer></app-footer>
  </div>