import { Component, OnInit, ViewChild } from '@angular/core';
import { CaptchaComponent } from 'angular-captcha';
import { ContactService } from 'src/app/services/contact.service';
import { ToastService } from 'src/app/services/message/toast.service';

declare const $: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;
  contact: any = {};
  constructor(
    private cService: ContactService,
    private mService: ToastService,
    ) {}

  ngOnInit(): void {
    this.captchaComponent.captchaEndpoint ='http://localhost:49980//simple-captcha-endpoint.ashx';

    setTimeout(() => {
      $("a[title ~= 'BotDetect']").removeAttr("style");
      $("a[title ~= 'BotDetect']").removeAttr("href");
      $("a[title ~= 'BotDetect']").css('visibility', 'hidden');
    }, 1000);
   

  }


  

  validate(): void {

    // get the user-entered captcha code value to be validated at the backend side        
    let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;

    // get the id of a captcha instance that the user tried to solve
    let captchaId = this.captchaComponent.captchaId;

    const postData = {
      userEnteredCaptchaCode: userEnteredCaptchaCode,
      captchaId: captchaId,
      ContactName: this.contact.name,
      ContactEmail:this.contact.emailaddress,
      Comment: this.contact.comment
    };

    // post the captcha data to the backend
    this.cService.addInquiry(postData)
      .subscribe(
        response => {
          if (response.success == false) {
            // captcha validation failed; reload image
            this.captchaComponent.reloadImage();
            // TODO: maybe display an error message, too
          } else {
            this.mService.generateMessage('Success', response.message, 'Success')
            // TODO: captcha validation succeeded; proceed with the workflow
          }
        });
  }
}
