
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
headers = headers.set('notoken', 'true');

@Injectable({
  providedIn: 'root'
})

export class PackageService {

  constructor(private httpclient: HttpClient, handler: HttpBackend) {
    this.httpclient = new HttpClient(handler);
  }
  public getPackages(): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/package/getallpackages`, {
      headers
    });
  }
  public subscribePackge(objpackage: any): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/payment/createpayment`, objpackage, {
      headers
    });
  }
}
