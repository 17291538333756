<div class="lp-main">
    <div class="lp-header-bg">
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <h3>Unique Case Finder management Made simple.</h3>
                    <p>lawsuitcasefinder.com is a prized possession of <span>"Levons Technologies Pvt. Ltd." </span>,
                        <br>
                        provider of worldwide Legal Information to attorneys and worldwide Legal Solutions to public.
                    </p>
                    <a href="#" class="lp-btn-style lp-btn-theme lp-btn-large d-inline-block mt-3">Download the app</a>
                </div>
            </div>
            <div class="container">
                <div class="d-lg-flex lp-slider-login-main lp-slider-contact-main">
                    
                    <div class="lp-right-login">  
                        <div class="lp-login-div-inner lp-slider-contact-inner">
    
                           <div class="login-1" data-tab-content="1">
                            <h3>Contact Info</h3>
                            <p>For any inquiry or any information about LawSuit and its services, please feel free to contact us,</p>
                            <form class="history-left-block"  novalidate #Inquiryform="ngForm" >
                                <div class="row">
                                    <div class="col-lg">
                                        <!-- <input class="form-control lp-input" placeholder="Your name *" type="text"> -->
                                        <input placeholder="Name" type="text" class="form-control lp-input"
                                        id="name"  data-name="name" [required]="true"
                                        data-prefix="name" data-type="text" name="name" [(ngModel)]="contact.name">
                                    </div>                                 
                                </div>
                                <div class="row">
                                    <div class="col-lg">
                                      <input placeholder="Your email address *" type="text" class="form-control lp-input"
                                        id="emailaddress"  data-name="emailaddress" [required]="true"
                                        data-prefix="emailaddress" data-type="text" name="emailaddress" [(ngModel)]="contact.emailaddress">
                                        <!-- <input class="form-control lp-input" placeholder="Your email address *" type="text"> -->
                                    </div>                                 
                                </div>
                                <div class="row">                              
                                    <div class="col-lg">
                                       <textarea placeholder="Comment *" style="resize: none;" class="form-control lp-input"
                                       id="comment"  data-name="comment" [required]="true"
                                       data-prefix="comment" data-type="text" name="comment" [(ngModel)]="contact.comment"
                                       ></textarea>
                                    </div>
                                </div>
                                <div class="row">                              
                                    <!-- <div class="col-lg">
                                      <div class="input-group mb-2 contact-block-bg ">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text input-group-text-captcha">
                                            <img src="assets/img/JpegImage.jpg" alt="" class="img-fluid">                                       
                                          </div>
                                          <div class="rectangle-text">
                                           <a href="#"> To Refresh Click On Image</a>                             
                                          </div>                                      
                                        </div>                                    
                                      </div>
                                      <div class="row">
                                        <div class="col-lg mt-2">
                                            <input class="form-control lp-input" placeholder="Please Write Above Code *" type="text">
                                        </div>                                 
                                    </div>
                                    </div> -->
                                    <botdetect-captcha captchaStyleName="yourFirstCaptchaStyle"></botdetect-captcha>  
                                    <input id="yourFirstCaptchaUserInput" 
                                           name="yourFirstCaptchaUserInput"
                                           ngModel
                                           #yourFirstCaptchaUserInput="ngModel"  
                                           type="text" >
                                </div>
                                <div class="mt-1">
                                     
                                    <div class="history-left-block d-inline-block">
                                      <ul>
                                        <li data-tab-li="4">
                                          <button type="button" class="form-btn yello-btn" aria-label="makeMeLogin"
                                          class="lp-button lp-login" appValidation [form]="Inquiryform"
                                          (click)="validate()" >Save</button>
                                          <!-- <a class="lp-button lp-login" (click)="validate()">Send Message</a> -->
                                        </li>

                                        </ul>                                
                                    </div>
     
                                </div>
                                 
                            </form>                         
                           </div>
     
                           
    
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <div class="az-content az-content-dashboard lp-content-dashboard pb-0">
        <div class="lp-top-contact-page">
            <div class="container">
                <div class="w-100 row m-0">
                    <div class="col-lg-5 col-md-12">
                      <div class="con-left">
                        
                        
                        <div class="d-flex bd-highlight mb-3">
                          <div class="pr-2 bd-highlight">                            
                            <img src="assets/img/map.png" class="img-fluid con-map-img" alt="map">
                          </div>
                          <div class="pl-2 add-rt">
                            <h2>Corporate Office</h2>
                            <h3>Levons Technologies Pvt. Ltd.</h3>
                            <p>Address: A-S/3, Raj Plaza Complex,
                               Above Corporation Bank, Nr. Sonal Cross Roads,
                               Gurukul Road, Memnagar, Ahmedabad - 380 052. 
                               Gujarat (INDIA)</p>
                          </div>                          
                        </div>

                        <div class="d-flex bd-highlight mb-3">
                          <div class="pr-2 bd-highlight">                            
                            <img src="assets/img/phone-call.png" class="img-fluid con-map-img" alt="map">
                          </div>
                          <div class="pl-2 add-rt con-flex-center-tb">
                            
                            <h3><span>Mobile:</span> +91-8000841444</h3>
                            
                          </div>                          
                        </div>

                        <div class="d-flex bd-highlight mb-3">
                          <div class="pr-2 bd-highlight">                            
                            <img src="assets/img/technical-support.png" class="img-fluid con-map-img" alt="map">
                          </div>
                          <div class="pl-2 add-rt">
                             <h3>Levons Emails</h3>
                            <h6>For Inquiry: <a href="mailto:info@levons.in"> info[at]levons[dot]in </a></h6>
                            <h6>Technical Support: <a href="mailto:support@levons.in"> support[at]levons[dot]in </a></h6>
                            <h6>Sales Inquiry: <a href="mailto:sales@levons.in"> sales[at]levons[dot]in</a> </h6>
                          </div>                          
                        </div>

                      </div>
                    </div>
                    <div class="col-lg-7 col-md-12">
                      <div class="con-right">

                        <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3671.2527822148963!2d72.53300630157472!3d23.05119237733324!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1416036941068" width="100%" height="446" frameborder="0" style="border:0"></iframe>
                      </div>
                    </div>
                </div>           
            </div>
        </div>
         

    </div>
    <app-footer></app-footer>
</div>