<!-- <script src="http://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3/jquery.easing.min.js"></script>
	<script src="http://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.2/modernizr.min.js"></script> -->
<div class="lp-main">

    <!-- <div style="width: 500px; margin-left: 30%;margin-top: 10px;">
        <br>
        <br>
        <br>
        <br>
        <br>
        <form novalidate #mainform="ngForm">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <label for="id" aria-label="Name"><strong>Username</strong></label>
                        <input aria-label="Email" type="text" placeholder="Email" [(ngModel)]="user.username"
                            name="Email" data-name="Email" [required]="true" data-prefix="Email" data-type="text"
                            class="form-control">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <label for="id" aria-label="Password"><strong>Password</strong></label>
                        <input aria-label="Password" type="password" placeholder="*****" [(ngModel)]="user.password"
                            name="Password" data-name="Password" [required]="true" data-prefix="Password"
                            data-type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                            [form]="mainform" [validparent]="'form-group'"
                            (click)="CheckLoginUser(mainform)">Login</button>
                    </div>
                </div>
            </div>
        </form>

        <form novalidate #ngSubActivationForm="ngForm">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <label for="id" aria-label="activationcode"><strong>Activation Code</strong></label>
                        <input aria-label="activationcode" type="text" placeholder="Activation Code"
                            [(ngModel)]="useractivation.activationcode" name="activationcode" data-name="activationcode"
                            [required]="true" data-prefix="Activation Code" data-type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                            [form]="ngSubActivationForm" [validparent]="'form-group'"
                            (click)="submitotp(ngSubActivationForm)">Submit OTP</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                            [form]="ngSubActivationForm" [validparent]="'form-group'"
                            (click)="resendotp(ngSubActivationForm)">Resend OTP</button>
                    </div>
                </div>
            </div>
        </form>
    </div> -->
    <div class="lp-header-bg">
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <!-- <h3>Unique Case Finder management Made simple.</h3>
                    <p>lawsuitcasefinder.com is a prized possession of <span>"Levons Technologies Pvt. Ltd." </span>,
                        <br>
                        provider of worldwide Legal Information to attorneys and worldwide Legal Solutions to public.
                    </p>
                    <a href="#" class="lp-btn-style lp-btn-theme lp-btn-large d-inline-block mt-3">Download the app</a>
                    style="font-family:GoodVibrations;"
                -->

                <h3> Research Simplified</h3>
                <p><span>Lawsuit-The Unique Case Finder<sup>&reg;</sup>  </span> e-library is simplest way of legal research. </p>
                  <!-- This will be eventual path of your legal research, by using this make your legal research very simple & fast.</p> -->
                    <a href="javascript:void(0)" (click)="RedirectFooter()" class="lp-btn-style lp-btn-theme lp-btn-large d-inline-block mt-3" >Download the app</a>

                </div>
            </div>
            <div class="container">
                <div class="d-lg-flex lp-slider-login-main flip3D">
                    <div class="lp-slider-div">
                        <div class="lp-left-slider">
                            <!-- slider -->
                            <div class="mockups">
                                <div class="css-mb with-glare">
                                    <div class="mb-display-position">
                                        <div class="mb-display">
                                            <div class="mb-screen-position">
                                                <div class="mb-screen">
                                                    <section id="mockup-slider" class="owl-carousel">
                                                        <div><a href="#"><img src="assets/img/screen-1.png"
                                                                    alt="Easy to research" /></a></div>
                                                        <div><a href="#"><img src="assets/img/screen-4.png"
                                                                        alt="Full text search" /></a></div>
                                                        <div><a href="#"><img src="assets/img/screen-2.png"
                                                                    alt="Research Simplified" /></a></div>
                                                        <div><a href="#"><img src="assets/img/screen-3.png"
                                                                    alt="Indian case law" /></a></div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-body"></div>
                                    <div class="mb-bottom-cover"></div>
                                </div>
                                <div class="controls">
                                    <a href="javascript:void(0);" class="prev">Prev</a>
                                    <a href="javascript:void(0);" class="next">Next</a>
                                </div>
                            </div>
                            <!-- slider -->
                        </div>
                    </div>


                    <div class="lp-right-login ">
                        <div class="front" id="divlogin">
                            <div class="bt-bg-login">
                                <!-- lp-login-div-inner search-history-tab-content -->
                                <div class="login-1" data-tab-content="1">
                                    <h3>User Login</h3>
                                    <form novalidate #mainform="ngForm" autocomplete="off">
                                        <div class="row">
                                            <div class="col-lg">
                                                <input class="form-control lp-input" type="text" placeholder="Username"
                                                    [(ngModel)]="user.username" name="Email" autocomplete="new-phone"  data-name="Email"
                                                    [required]="true" data-prefix="Email" data-type="text">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg">
                                                <input class="form-control lp-input" autocomplete="false" type="password" placeholder="*****"
                                                    [(ngModel)]="user.password" name="Password" data-name="Password"
                                                    [required]="true" data-prefix="Password" data-type="text">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg">
                                                <label class="ckbox mg-b-5">
                                                    <input id="rem" type="checkbox" name="court" class="ctree" value="1"
                                                        data-parsley-mincheck="2" [(ngModel)]="user.remember"
                                                        data-parsley-class-handler="#cbWrapper3"
                                                        data-parsley-errors-container="#cbErrorContainer3"
                                                        data-parsley-multiple=""><span>Remember me</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mt-1">
                                            <button class="lp-button lp-login" appValidation [form]="mainform"
                                                [validparent]="'col-lg'"
                                                (click)="CheckLoginUser(mainform)">Login</button>
                                            <a href="javascript:void(0)" [routerLink]="['/registration']"
                                                class="lp-button lp-register">Register</a>
                                        </div>
                                        <div>
                                            <a href="javascript:void()" style="display: inline !important;" (click)="openpasswordforgot()"
                                                class="forgot-p">Forgot Password?</a>
                                            <!-- <a href="https://oldweb.lawsuitcasefinder.com/" target="_blank"
                                            style="text-decoration: underline;color:red !important; cursor:pointer;"  
                                                class="forgot-p">Go to old website</a> -->
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                        <div class="back">
                            <div class="lp-login-div-inner pageforgot">
                                <div id="divforgotpassword">
                                    <h3>Forgot password</h3>
                                    <form novalidate #formForgotpwd="ngForm">
                                        <div class="row">

                                            <div class="col-lg">
                                                <input class="form-control lp-input" placeholder="Enter Mobile / Email "
                                                    name="useremailaddress" [(ngModel)]="useremailaddress" type="text"
                                                    data-name="useremailaddress" [required]="true" data-prefix="useremailaddress"
                                                    data-type="text" />
                                            </div>
                                        </div>
                                        <div class="mt-1">
                                            <div class="history-left-block">
                                                <ul>
                                                    <li data-tab-li="3">
                                                        
                                                        <button aria-label="makeMeLogin" class="lp-button lp-login" appValidation
                                                        [form]="formForgotpwd" [validparent]="'col-lg 2'" (click)="sendforgotpasswordotp(formForgotpwd)">Send OTP</button>
                                                        <!-- <a class="lp-button lp-login"
                                                            (click)="sendforgotpasswordotp(formForgotpwd)">Send OTP</a> -->
                                                    </li>
                                                    <li class="forgot-li" data-tab-li="1">
                                                        <a href="javascript:void()" (click)="backtologin()"
                                                            class="forgot-p">Back to Login?</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div id="divconfirmotp">
                                    <div id="divconfirmsendotp">
                                        <h3>Confirm OTP</h3>
                                        <form #formConfirmOTP="ngForm">
                                            <div class="row">
                                                <div class="col-lg">
                                                    <input class="form-control lp-input" placeholder="Enter OTP"
                                                        name="confirmotp" [(ngModel)]="confirmotp" type="text"
                                                        data-name="confirmotp" [required]="true"
                                                        data-prefix="confirmotp" data-type="text" />
                                                </div>
                                            </div>
                                            <div class="mt-1">

                                                <div class="history-left-block">
                                                    <ul>
                                                        <li data-tab-li="3">

                                                            <button role="button" aria-label="makeMeLogin" class="lp-button lp-login" appValidation
                                                            [form]="formConfirmOTP" [validparent]="'col-lg'" (click)="updateconfimotpuser(formConfirmOTP)">Submit</button>
                                                            <!-- <a class="lp-button lp-login"
                                                                (click)="updateconfimotpuser(formConfirmOTP)">Submit</a> -->
                                                        </li>
                                                        <li class="forgot-li" data-tab-li="1">
                                                            <a href="javascript:void()"
                                                                (click)="ResendOTPpassword(formConfirmOTP)"
                                                                class="forgotcollg">Resend OTP</a>

                                                                <a href="javascript:void()" (click)="backtologin()"
                                                                class="forgot-p">Back to Login?</a>
                                                        </li>
                                                      
                                                    </ul>
                                                </div>


                                            </div>

                                        </form>

                                    </div>

                                    <div id="divupdatepassword">
                                        <h3>Enter New Password</h3>
                                        <form #formUpdatePaaword="ngForm">
                                            <div class="row">
                                                <div class="col-lg">
                                                    <input class="form-control lp-input" type="password"
                                                        placeholder="*****" [(ngModel)]="olduser.password"
                                                        name="password" data-name="password" [required]="true"
                                                        data-prefix="password" data-type="text">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg">
                                                    <input class="form-control lp-input" type="password"
                                                        placeholder="*****" [(ngModel)]="olduser.confirmpassword"
                                                        name="confirmpassword" data-name="confirmpassword"
                                                        [required]="true" data-prefix="confirmpassword"
                                                        data-type="text">
                                                </div>
                                            </div>
                                            <div class="mt-1">

                                                <div class="history-left-block">
                                                    <ul>
                                                        <li data-tab-li="3">
                                                            <button role="button" aria-label="makeMeLogin" class="lp-button lp-login" appValidation
                                                            [form]="formUpdatePaaword" [validparent]="'col-lg'" (click)="UpdateUserPassword(formUpdatePaaword)">Submit</button>
<!-- 
                                                            <a class="lp-button lp-login"
                                                                (click)="UpdateUserPassword(formUpdatePaaword)">Set
                                                                Password</a> -->
                                                        </li>
                                                        <li class="forgot-li" data-tab-li="1">
                                                            <a href="javascript:void()" (click)="backtologin()"
                                                                class="forgot-p">Back to Login?</a>
                                                        </li>
                                                    </ul>
                                                </div>


                                            </div>

                                        </form>

                                    </div>
                                </div> 
                                <div id="divactivationcode">
                                    <h3>Activation Code</h3>
                                    <form novalidate #ngSubActivationForm="ngForm">
                                     
                                        <div class="row">                              
                                            <div class="col-lg">
                                                <input class="form-control lp-input" placeholder="Enter activationcode" name="activationcode" [(ngModel)]="useractivation.activationcode"
                                                type="text"  data-name="activationcode" [required]="true" data-prefix="activationcode" data-type="text" />
                                            </div>
                                        </div>
                                        <!-- <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group form-group-lg">
                                                    <label for="id" aria-label="activationcode"><strong>Activation Code</strong></label>
                                                    <input aria-label="activationcode" type="text" placeholder="Activation Code"
                                                        [(ngModel)]="useractivation.activationcode" name="activationcode" data-name="activationcode"
                                                        [required]="true" data-prefix="Activation Code" data-type="text" class="form-control">
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="mt-1">
                                                 
                                            <div class="history-left-block">
                                              <ul>
                                                <li data-tab-li="3">
                                                    <button role="button" aria-label="makeMeLogin" class="lp-button lp-login" appValidation
                                                    [validparent]="'col-lg'"  (click)="submitotp(ngSubActivationForm)" [form]="ngSubActivationForm">Submit</button>
                                                  <!-- <a class="lp-button lp-login" (click)="submitotp(ngSubActivationForm)" [form]="ngSubActivationForm" appValidation [validparent]="'form-group'"  >Submit</a> -->
                                                </li>
                                                <li class="forgot-li" data-tab-li="1">
                                                    <a href="javascript:void()" (click)="resendotp(ngSubActivationForm)" [form]="ngSubActivationForm" appValidation [validparent]="'form-group'" class="forgot-p">Resend OTP</a>
                                                    <a href="javascript:void()" (click)="backtologin()" class="forgot-p">Back to Login?</a>
                                                </li>
                                                </ul>                                
                                            </div> 
                                            
                                              
                                          </div>
                                        <!-- <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group form-group-lg">
                                                    <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                                                        [form]="ngSubActivationForm" [validparent]="'form-group'"
                                                        (click)="submitotp(ngSubActivationForm)">Submit OTP</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group form-group-lg">
                                                    <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                                                        [form]="ngSubActivationForm" [validparent]="'form-group'"
                                                        (click)="resendotp(ngSubActivationForm)">Resend OTP</button>
                                                </div>
                                            </div>
                                        </div> -->
                                    </form>
                                </div>                     
                            </div>
                        </div>
                    </div>


                    <!--                     
                    <div class="lp-right-login front" id="divlogin">
                        <div class="lp-login-div-inner">
                            <h3>User Login</h3>
                            <form novalidate #mainform="ngForm">
                                <div class="row">
                                    <div class="col-lg">
                                        <input class="form-control lp-input" type="text" placeholder="Username"
                                            [(ngModel)]="user.username" name="Email" data-name="Email" [required]="true"
                                            data-prefix="Email" data-type="text">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg">
                                        <input class="form-control lp-input" type="password" placeholder="*****"
                                            [(ngModel)]="user.password" name="Password" data-name="Password"
                                            [required]="true" data-prefix="Password" data-type="text">
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <button class="lp-button lp-login" appValidation [form]="mainform"
                                        [validparent]="'col-lg'" (click)="CheckLoginUser(mainform)">Login</button>
                                <a href="javascript:void(0)"  [routerLink]="['/registration']" class="lp-button lp-register">Register</a>
                                </div>
                                <div>
                                    <a href="javascript:void()" (click)="openpasswordforgot()" class="forgot-p">Forgot Password?</a>
                                </div>
                            </form>
                        </div>
                    </div>                    
                    <div class="lp-right-login lp-right-login1 back">
                        <div class="lp-login-div-inner pageforgot">
                            <div id="divforgotpassword">                           
                            <h3>Forgot password</h3>
                            <form #formForgotpwd="ngForm"> 
                                <div class="row">                              
                                  <div class="col-lg">
                                      <input class="form-control lp-input" placeholder="Email Address" name="useremailaddress" [(ngModel)]="useremailaddress"
                                      type="text"  data-name="Email" [required]="true" data-prefix="Email" data-type="text" />
                                  </div>
                              </div>
                                  <div class="mt-1">
                                    <div class="history-left-block">
                                      <ul>
                                        <li data-tab-li="3">
                                          <a class="lp-button lp-login" (click)="sendforgotpasswordotp(formForgotpwd)">Send OTP</a>
                                        </li>
                                        <li class="forgot-li" data-tab-li="1">
                                            <a href="javascript:void()" (click)="backtologin()" class="forgot-p">Back to Login?</a>
                                        </li>
                                        </ul>                                
                                    </div> 
                                  </div>                      
                              </form>     
                            </div> 
                             <div id="divconfirmotp" >
                                <div id="divconfirmsendotp">
                                    <h3>Confirm OTP</h3>
                                    <form #formConfirmOTP="ngForm"> 
                                        <div class="row">                              
                                          <div class="col-lg">
                                              <input class="form-control lp-input" placeholder="Enter OTP" name="confirmotp" [(ngModel)]="confirmotp"
                                              type="text"  data-name="confirmotp" [required]="true" data-prefix="confirmotp" data-type="text" />
                                          </div>
                                      </div>
                                          <div class="mt-1">
                                             
                                            <div class="history-left-block">
                                              <ul>
                                                <li data-tab-li="3">
                                                  <a class="lp-button lp-login" (click)="updateconfimotpuser(formConfirmOTP)">Submit</a>
                                                </li>
                                                <li class="forgot-li" data-tab-li="1">
                                                    <a href="javascript:void()" (click)="sendforgotpasswordotp(formConfirmOTP)" class="forgot-p">Resend OTP</a>
                                                </li>
                                                </ul>                                
                                            </div> 
                                            
                                              
                                          </div>
                                                                      
                                      </form>
                                
                                </div>
        
                                <div id="divupdatepassword">
                                    <h3>Enter New Password</h3>
                                    <form #formUpdatePaaword="ngForm"> 
                                        <div class="row">
                                            <div class="col-lg">
                                                <input class="form-control lp-input" type="password" placeholder="*****"
                                                    [(ngModel)]="olduser.password" name="Password" data-name="Password"
                                                    [required]="true" data-prefix="Password" data-type="text">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg">
                                                <input class="form-control lp-input" type="password" placeholder="*****"
                                                    [(ngModel)]="olduser.confirmpassword" name="confirmpassword" data-name="confirmpassword"
                                                    [required]="true" data-prefix="confirmpassword" data-type="text">
                                            </div>
                                        </div>
                                          <div class="mt-1">
                                             
                                            <div class="history-left-block">
                                              <ul>
                                                <li data-tab-li="3">
                                                  <a class="lp-button lp-login" (click)="UpdateUserPassword(formUpdatePaaword)">Set Password</a>
                                                </li>
                                                <li class="forgot-li" data-tab-li="1">
                                                    <a href="javascript:void()" (click)="backtologin()" class="forgot-p">Back to Login?</a>
                                                </li>
                                                </ul>                                
                                            </div> 
                                            
                                              
                                          </div>
                                                                      
                                      </form>
                                
                                </div>
                            </div>                      
                        </div>
                    </div>
                      -->

                </div>
            </div>
        </div>
    </div>
    <div class="az-content az-content-dashboard lp-content-dashboard pb-0">
        <div class="lp-top-welcome-page">
            <div class="container">
                <div class="w-100 text-center">
                    <h1 class="lp-h1-tl"> <img src="assets/img/lp-welcome-logo.png" alt="lawsuitcasefinder"> Who We Are ?</h1>
                    <p class="lp-top-block-p">"lawsuitcasefinder.com" is a Legal Research for Legal Professional,which
                        provides worldwide legal information to attorneys, from dozen of publishers around the world &
                        Legal Solution for Public to find a Lawyer, Attorneys, Local Law Firms and free legal
                        information at one place and worldwide Legal Solutions to public, which is introduced by
                        "Levons Technologies Pvt. Ltd."</p>
                    <p class="lp-top-block-p">Our purpose is to develop Law Portal wherein worldwide Legal Information
                        is available. Also where Legal Professional can get any information regarding legal field,
                        Attorneys can also solve their problems, Law students can connect to Law Firm. Corporate Firm
                        could find an attorney, legal Firms, legal aid services. In a nutshell, we are all about Legal
                        Solutions at one stop.</p>
                    <p><strong>Now E-library Supports on </strong> <img src="assets/img/about-bt-img.png" alt="About Lawsuit" width="280">
                    </p>
                </div>
            </div>
        </div>
        <div class="lp-center-block shape-bg">
            <div class="container">
                <div class="row w-100">
                    <div class="col-sm-4">
                        <h3 class="articles-tl">Our <span>Articles</span> <a href="#">View All</a> </h3>
                        <div class="articles-slider">
                            <div class="wt-100">
                                <div id="carouselExample3" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExample3" data-slide-to="0" class="active"></li>
                                        <li data-target="#carouselExample3" data-slide-to="1"></li>
                                        <li data-target="#carouselExample3" data-slide-to="2"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="lp-articles-slider-list">
                                                <div class="lp-articles-slider-list-img">
                                                    <img src="assets/img/lp-articles-slider-img.jpg" alt="Lawsuit Article">
                                                </div>
                                                <div class="lp-articles-slider-list-content">
                                                    <h3>Environment and Sustainable Development</h3>
                                                    <p>The debate between protection of the nvironment and sustainable
                                                        development is an age old... growing in proportion with every
                                                        passing day... </p>
                                                    <a href="#" class="art_plus_link">+</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="lp-articles-slider-list">
                                                <div class="lp-articles-slider-list-img">
                                                    <img src="assets/img/lp-articles-slider-img.jpg" alt="Lawsuit Article">
                                                </div>
                                                <div class="lp-articles-slider-list-content">
                                                    <h3>Environment and Sustainable Development</h3>
                                                    <p>The debate between protection of the nvironment and sustainable
                                                        development is an age old... growing in proportion with every
                                                        passing day... </p>
                                                    <a href="#" class="art_plus_link">+</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="lp-articles-slider-list">
                                                <div class="lp-articles-slider-list-img">
                                                    <img src="assets/img/lp-articles-slider-img.jpg" alt="Lawsuit Article">
                                                </div>
                                                <div class="lp-articles-slider-list-content">
                                                    <h3>Environment and Sustainable Development</h3>
                                                    <p>The debate between protection of the environment and sustainable development is an age old... growing in proportion with every
                                                        passing day... </p>
                                                    <a href="#" class="art_plus_link">+</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <a class="carousel-control-prev" href="#carouselExample3" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"><i data-feather="chevron-left"></i></span>
                                <span class="sr-only">Previous</span>
                              </a>
                              <a class="carousel-control-next" href="#carouselExample3" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"><i data-feather="chevron-right"></i></span>
                                <span class="sr-only">Next</span>
                              </a> -->
                                </div>
                            </div>
                        </div>

                        <div class="latest-news">
                            <h3 class="articles-tl">Latest <span>News</span> <a href="#">View All</a> </h3>
                            <div class="lp-news-div">
                                <ul>
                                    <li>
                                        <span>23 May, 2020</span>
                                        <h3><a href="#">Interface between Competition and
                                                Intellectual property law</a> </h3>
                                        <p>The debate between protection of the nvironment and sustainable development
                                            is an age old...</p>
                                    </li>
                                    <li>
                                        <span>23 May, 2020</span>
                                        <h3><a href="#">Interface between Competition and
                                                Intellectual property law</a> </h3>
                                        <p>The debate between protection of the nvironment and sustainable development
                                            is an age old...</p>
                                    </li>
                                    <li>
                                        <span>23 May, 2020</span>
                                        <h3><a href="#">Interface between Competition and
                                                Intellectual property law</a> </h3>
                                        <p>The debate between protection of the nvironment and sustainable development
                                            is an age old...</p>
                                    </li>
                                    <li>
                                        <span>23 May, 2020</span>
                                        <h3><a href="#">Interface between Competition and
                                                Intellectual property law</a> </h3>
                                        <p>The debate between protection of the nvironment and sustainable development
                                            is an age old...</p>
                                    </li>
                                    <li>
                                        <span>23 May, 2020</span>
                                        <h3><a href="#">Interface between Competition and
                                                Intellectual property law</a> </h3>
                                        <p>The debate between protection of the nvironment and sustainable development
                                            is an age old...</p>
                                    </li>
                                    <li>
                                        <span>23 May, 2020</span>
                                        <h3><a href="#">Interface between Competition and
                                                Intellectual property law</a> </h3>
                                        <p>The debate between protection of the nvironment and sustainable development
                                            is an age old...</p>
                                    </li>
                                    <li>
                                        <span>23 May, 2020</span>
                                        <h3><a href="#">Interface between Competition and
                                                Intellectual property law</a> </h3>
                                        <p>The debate between protection of the nvironment and sustainable development
                                            is an age old...</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-8">
                        <div class="latest-case-main">
                            <div class="latest-case-main-tl">
                                <h3> Latest<span> Cases </span></h3>
                            </div>
                            <div class="latest-case-content">
                                <div class="card bd-0">
                                    <div class="card-header bd-b-0-f pd-b-0">
                                        <nav class="nav nav-tabs">
                                            <a class="nav-link active" data-toggle="tab" href="#tabCont1"
                                                (click)="getsupremecourtdata(1)">Supreme
                                                Court</a>
                                            <span class="tab-center-boder"></span>
                                            <a class="nav-link" data-toggle="tab" href="#tabCont2"
                                                (click)="getsupremecourtdata(2)">High Courts</a>
                                            <!-- <a class="nav-link" data-toggle="tab" href="#tabCont3">View All</a> -->
                                        </nav>
                                    </div><!-- card-header -->
                                    <div class="card-body bd-t-0 tab-content">
                                        <div id="tabCont1" class="tab-pane active show">
                                            <div class="lp-tab-view-all">
                                                <!-- <a href="#">View All</a> -->
                                            </div>
                                            <div class="lp-tab-content-list">
                                                <ul>
                                                    <li
                                                        *ngFor="let d of supremecasedata;trackBy: trackByIndex; index as dindex; last as last">
                                                        <div>
                                                            <div class="media">
                                                                <div class="mr-3">
                                                                    <p class="dt-mo-ye-p">
                                                                        <span
                                                                            class="dt-span">{{d._source.dispose_dt | date:'dd'}}</span>
                                                                        <span
                                                                            class="mo-span">{{d._source.dispose_dt | date:'LLL'}}</span>
                                                                        <span
                                                                            class="ye-span">{{d._source.dispose_dt | date:'yyyy'}}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="media-body lp-tab-content-list-content">
                                                                    <h5 class="mt-0">{{d._source.appellant}} VS
                                                                        {{d._source.respondent}}
                                                                    </h5>
                                                                    <p> {{d._source.hcref | uppercase}}</p>
                                                                    <p *ngIf="d._source.topic != ''"><span>Subject:
                                                                        </span> {{d._source.topic}}</p>
                                                                    <p *ngIf="d._source.fd != ''"> <a href="#">Final
                                                                            Decision:</a> {{d._source.fd}}
                                                                    </p>
                                                                    <a href="#" class="lp-tab-content_plus_link">+</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div><!-- tab-pane -->
                                        <div id="tabCont2" class="tab-pane">
                                            <div class="lp-tab-view-all">
                                                <!-- <a href="#">View All</a> -->
                                            </div>
                                            <div class="lp-tab-content-list">
                                                <ul>
                                                    <li
                                                        *ngFor="let d of supremecasedata;trackBy: trackByIndex; index as dindex; last as last">
                                                        <div>
                                                            <div class="media">
                                                                <div class="mr-3">
                                                                    <p class="dt-mo-ye-p">
                                                                        <span
                                                                            class="dt-span">{{d._source.dispose_dt | date:'dd'}}</span>
                                                                        <span
                                                                            class="mo-span">{{d._source.dispose_dt | date:'LLL'}}</span>
                                                                        <span
                                                                            class="ye-span">{{d._source.dispose_dt | date:'yyyy'}}</span>
                                                                    </p>
                                                                </div>
                                                                <div class="media-body lp-tab-content-list-content">
                                                                    <h5 class="mt-0">{{d._source.appellant}} VS
                                                                        {{d._source.respondent}}
                                                                    </h5>
                                                                    <p> {{d._source.hcref | uppercase}}</p>
                                                                    <p *ngIf="d._source.topic != ''"><span>Subject:
                                                                        </span> {{d._source.topic}}</p>
                                                                    <p *ngIf="d._source.fd != ''"> <a href="#">Final
                                                                            Decision:</a> {{d._source.fd}}
                                                                    </p>
                                                                    <a href="#" class="lp-tab-content_plus_link">+</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div id="tabCont3" class="tab-pane">This is tab content 3...</div>
                                    </div><!-- card-body -->
                                </div><!-- card -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lp-center-block-bt">
            <div class="container">
                <div class="footer-block-slider">
                    <div class="d-lg-flex d-md-flex" id="">
                        <div class="items col-md-4">
                            <div class="black-bg">
                                <div class="black-bg-img">
                                    <img src="assets/img/footer-slider-1.png" alt="Lawsuit footer">
                                </div>
                                <div class="black-bg-content">
                                    <h4>Coverage of worldwide</h4>
                                    <p>Greater coverage of world wide case law and legislation with amendments in single
                                        searching.</p>
                                </div>
                            </div>
                        </div>
                        <div class="items col-md-4">
                            <div class="black-bg">
                                <div class="black-bg-img">
                                    <img src="assets/img/footer-slider-2.png" alt="Data Search">
                                </div>
                                <div class="black-bg-content">
                                    <h4>Data Search Options</h4>
                                    <p>Elegant full text search in addition to Boolean, advanced as well as equivalent
                                        citations.</p>
                                </div>
                            </div>
                        </div>
                        <div class="items col-md-4">
                            <div class="black-bg">
                                <div class="black-bg-img">
                                    <img src="assets/img/footer-slider-3.png" alt="Customized Result">
                                </div>
                                <div class="black-bg-content">
                                    <h4>Customized Result </h4>
                                    <p>Customized result view which can be sorted on any criteria as per your relevance.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mobail-block">
        <div class="wpb_wrapper">
            <div id="feature" class="feature-area section-padding">
                <div class="container">
                    <div class="row">
                        <div class="service-tl">
                            <h3>Features as well as <span>Services</span></h3>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="feature-content feature-left text-left col-md-4 col-md-offset-0 col-sm-8 col-sm-offset-2 col-xs-12">
                            <div class="single-feature fix">
                                <img src="assets/img/mobail-screen-icon.png" alt="Updates of worldwide case law" />
                                <p>Daily Updates of worldwide case law and legislation with amendments is maintained.
                                </p>
                            </div>
                            <div class="single-feature fix">
                                <img src="assets/img/mobail-screen-icon.png" alt="Connect to Legal Minds" />
                                <h4> Connect to Legal Minds</h4>
                                <p>Virtual legal community including blogs, mailing list archives and message boards.
                                </p>
                            </div>
                            <div class="single-feature fix">
                                <img src="assets/img/mobail-screen-icon.png" alt="Career Center" />
                                <h4>Career Center</h4>
                                <p>The most complete online legal career center, with law firm salary charts, legal job
                                    listings, personalized legal job placement services and legal discussion forums.</p>
                            </div>
                        </div>
                        <div
                            class="feature-image col-md-4 col-md-offset-0 col-sm-8 col-sm-offset-2 col-xs-12 text-center">
                            <img src="assets/img/mobail-screen.png" alt="case law" /></div>

                        <div
                            class="feature-content feature-right text-left col-md-4 col-md-offset-0 col-sm-8 col-sm-offset-2 col-xs-12">
                            <div class="single-feature fix">
                                <img src="assets/img/mobail-screen-icon.png"  alt="Newsletters and Opinion Summaries" />
                                <h4>Newsletters and Opinion Summaries </h4>
                                <p>Legal news update for 24 hours and range of legal topics. Email Newsletters, Daily
                                    Opinion Summaries, Legal Headlines and Topical Newsletters.</p>
                            </div>
                            <div class="single-feature fix">
                                <img src="assets/img/mobail-screen-icon.png" alt="download customized results" />
                                <p>Options to print and download customized results & documents and Get alerts on set
                                    criteria on any new addition of cases.</p>
                            </div>
                            <div class="single-feature fix">
                                <img src="assets/img/mobail-screen-icon.png" alt="citations" />
                                <p>Navigate to any hyperlinks of citations, documents or paragraph mentioned in
                                    Judgments.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="service-btn">
                            <a href="" [routerLink]="['/features']">View All Features</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>