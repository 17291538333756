import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandcondition',
  templateUrl: './termsandcondition.component.html',
  styleUrls: ['./termsandcondition.component.scss']
})
export class TermsandconditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
