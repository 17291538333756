<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">
  
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <h3>Data Coverage</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">Data Coverage</li>
                    </ol>
                </div>
            </div>
  
        </div>
  
    </div>
    <!-- az-header -->
    <div class="az-content az-content-dashboard lp-content-dashboard pb-0 inner-main inner-about">
        
        <div class="w-100 ">               
          <div class="container">
            <div class="row w-100">
                
                <div class="col-lg-12 col-md-12">
                  <div class="flex-center-1">
                    <div class="flex-center-2">
                      <!-- <h3 class="inner-tl"> WHO  <span>WE ARE:</span></h3> -->
                        <div class="list-group">
                          <p>Lawsuit offers you instantaneous access 24*7 hours to advanced coverage published by wide variety of legal sources across the world. It provides cases from almost all the major countries like India, U.K., U.S., Canada, Malaysia, Australia, South Africa, Singapore and Sri Lanka.</p>
                          <p>It covers cases on subjects referred world wide such as Human Rights, Intellectual Property, Arbitration, Media, Banking, Shipping, Immigration, Environment, Cyber, Taxation, Civil, Criminal, Consumer Protection and many more.</p>

                          <h3 class="inner-tl mt-3 mb-0 pb-0"> <span>Indian Case Law includes: </span> </h3> 
                          <ul>
                            <li>More than 13,80,000 cases of Supreme Court, All High Courts, Tribunal Courts, Federal Courts & Privy Council of India.</li>
                            <li>Subscribing to Lawsuit gives you access to vast pool of judicial hearings ever since 1860 (almost 15 decades now).</li>
                            <li>It also offers citations from famous journals like AIR(SC), SCC, SCR, CrLJ, Crimes, JT, Supreme Today, Scale, Indian Cases, ELT, ITR, Taxman, LLJ, CompCase, ACJ and also offers state wise eq. citations like MadLJ, BLR, BCR, AIR (High Courts), DLT, AD(Del), ALT, GLR, CalLT and many more.</li>
                            <li>More than 39,900 Central and State bare acts with rules, regulations, orders and schemes.</li>
                            <li>Judgments on all Subjects of Law, like Human Rights, Intellectual Property, Arbitration, Media, Banking, Shipping, Immigration, Environment, Cyber, Taxation, Civil, Criminal, Consumer Protection and many more.</li>
                            <li>Internal hyperlinks for referring world wide cases mentioned within the judgment provided.</li>
                          </ul>

                          <h4 class="tl-3-lp">U.K Case Law includes:</h4>
                          <p> More than 2,50,000 cases since 1220 of Supreme Court England and Wales, Court of Appeal of England and Wales (Civil & Criminal Division), High Court of Justice (Queen's Bench, Chancery & Family Division), Other Law Reports (King's Bench, All England Reports, Appeal Cases)</p>
                          <p>It also offers citations from famous journals like Weekly Law Reports, The Law Reports, Times Law Reports, All England Law Reports, International Law Reports, England Reports, Daily Cases and many more reports like (Business Law Reports, CCH Tax Cases, Criminal Appeal Reports, Industrial Cases Reports, Inquest Law Reports, Mental, Health Law Reports, Police Law Reports, Prison Law Reports)</p> 


                          <h4 class="tl-3-lp">Upcoming Data Coverage:</h4>
                           <ul class="pt-1">
                             <li>U.K. Legislation, Irish & Scot Case Law</li>
                             <li>U.S. Supreme Court, All Federal Circuit Courts, Federal District Courts, All 50 States District Courts & Other Courts</li>
                             <li>Canada Supreme Court and SCR Citations   </li>
                             <li>Malaysia Case Laws covers CLJ, LN Series (LNS), MCLJ, MyCLJ</li>
                             <li>Western Australia Supreme Court & Other law reports</li>
                             <li>South Africa Case Laws covers Supreme Court of Appeal</li>
                           </ul>


                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
          </div>
                   
        </div>
          
    </div>
    <app-footer></app-footer>
  </div>