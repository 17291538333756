<div class="lp-main lp-main-inner">
    <div class="lp-header-bg">
  
        <app-navigation></app-navigation>
        <div>
            <div class="container">
                <div class="lp-banner-text">
                    <h3>Terms and Condition</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="white-text" [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active">Terms and Condition</li>
                    </ol>
                </div>
            </div>
  
        </div>
  
    </div>
    <!-- az-header -->
    <div class="az-content az-content-dashboard lp-content-dashboard pb-0 inner-main inner-about">
        
        <div class="w-100 ">               
          <div class="container">
            <div class="row w-100">
                
                <div class="col-lg-12 col-md-12">
                  <div class="flex-center-1">
                    <div class="flex-center-2">
                      <!-- <h3 class="inner-tl"> WHO  <span>WE ARE:</span></h3> -->
                        <div class="list-group">
                          <p>lawsuitcasefinder.com is an Internet-based service that provides access to Lawsuit. Lawsuit grants Subscriber a non-exclusive, non-transferable, limited license to use lawsuitcasefinder.com (including all versions and updates). Subscriber may not reverse engineer, decompile, disassemble or otherwise attempt to discern the source code, of the components of lawsuitcasefinder.com nor Subscriber reproduce all or any portion of the components of lawsuitcasefinder.com Subscriber may use Data cached in Subscriber's local disk drive solely in support of its use of lawsuitcasefinder.com. Certain software used by Subscriber may not be capable of supporting lawsuitcasefinder.com. The performance of lawsuitcasefinder.com varies with the manufacturers' equipment with which it is used. </p>

                          <h3 class="inner-tl"> <span>Grant </span> </h3> 
                          <h4 class="tl-3-lp">i. Grant:</h4>
                          <p> Lawsuit is internet based legal research service. Subscriber is granted a non-exclusive, non-transferable, limited license to access http://www.lawsuitcasefinder.com.</p>

                          <h4 class="tl-3-lp">ii. Limitations:</h4>
                          <p> Subscriber may not copy, download, store, publish distribute, transfer, circulate, sell, or use this or otherwise use the Data, or any portion of the Data, in any form or by any means, except to use this data for Legal guidance, research, for courtroom discussion or for academic purpose. You accept and acknowledge that you will not use this database for any other purpose other than permitted in this terms.</p>

                          <h4 class="tl-3-lp">c. Rights in Data.</h4>
                          <p> Except for the license granted in this Agreement, all rights, title and interest in Data, in all languages, formats and media throughout the world, including all copyrights, are and will continue to be the exclusive property of Lawsuit.</p>
                          <p>The services so offered are subject to the user agreeing to all the terms and conditions of the license. <br>
                            Lawsuit grants the user a non-exclusive, non-transferable, revocable, limited license to access and use the Web Site.<br>
                            Subscriber has a limited right to use the content downloaded from our Web Site strictly for personal use only.<br>
                            Lawsuit reserves the right to terminate this license at any time for any reason.
                            No material/content downloaded from the Web Site of Lawsuit shall be reproduced, transmitted or stored in any other Web Site nor shall any of its pages be disseminated, either in electronic or non-electronic form, or included in any public or private electronic retrieval system or service without the prior written permission of Lawsuit.<br>
                            It is made abundantly clear that Lawsuit does not have absolute control over the contents posted on the web site and hence does not guarantee the accuracy, quality or integrity of such content. The views expressed in the article section of the web site are those of the respective authors and not of Lawsuit. Any illegal or offensive content posted on the site, if detected, should be brought to Lawsuit's attention for immediate action. Lawsuit will not be responsible in any manner for any defamatory or contemptuous matter posted herein.</p>


                          <h4 class="tl-3-lp">2. Security</h4>
                          <p> Your username and password will be your identity for purposes of interacting with Lawsuit and other users through the site.<br>
                            You shall keep confidential, shall not disseminate, and shall use solely in accordance with this Agreement, your username and password for the Service. You shall immediately notify Lawsuit if you learn of or suspect:<br>  
                            any loss or theft of your username or password, or
                            any unauthorized use of your username or password or of the Service. In the event of such loss, theft, or unauthorized use, Lawsuit may impose on you, at Lawsuit's sole discretion, additional security obligations.<br>
                            If any unauthorized person obtains access to the Service as a result of any act or omission by you, you shall use your best efforts to ascertain the source and manner of acquisition and shall fully and promptly brief Lawsuit. You shall otherwise cooperate and assist in any investigation relating to any such unauthorized access.</p>

                          <h4 class="tl-3-lp">3. Charges</h4>
                          <p> Subscriber hereby authorizes Lawsuit to bill the credit card entered in Subscriber's registration for all monthly charges incurred on http://www.lawsuitcasefinder.com by Credit Card under Subscriber's personal password. Charges for use of Lawsuit will be billed at then-current rates. Charges are exclusive of sales, use, value added tax (VAT) or equivalent, ad valorem, personal property and other taxes, which are the responsibility of Subscriber. If your credit card is declined, Subscriber may be charged interest for overdue charges. All collection fees, including but not limited to attorneys fees, are payable by Subscriber. Lawsuit charges are non-refundable.</p>
                          
                          <h4 class="tl-3-lp">4. Payments </h4>
                          <p>Payments can be made either by a cheque/DD in favor of Levons Technologies Pvt. Ltd. However online payments can be made through credit card.</p>

                          <h5 class="tl-3-lp">5. MODIFICATIONS</h5>
                          <p> The prices, terms and conditions and/ or other matters provided in the Price List, and any modifications carried out by Lawsuit and any notices served upon the subscriber by Lawsuit shall be deemed to be included in the terms and conditions herein.</p>
                          <p>Lawsuit reserves the right to modify the terms and conditions including the subscription and the charges, and to introduce new ones. Such modifications shall become effective forthwith on the issue of either specifically to the subscriber or generally a notice to that effect by Lawsuit by electronic mail.</p>
                          <p>The subscription amount paid by the subscriber shall be for the fixed term/ plan and any modification in the subscription charges shall be applicable on the commencement of next renewed plan term. However, the introduction of any additional feature/ database by third party content provider through Lawsuit will be made available to the existing subscriber only after making up the deficit payment.</p>
                          <p>The subscriber's continued use of service after the publication of the notice conveying the modification shall conclusively be deemed to be the acceptance of the modified terms and conditions. </p>
                          <p>Lawsuit has the right to prevent access to all or part of the Web Site without notice, if the conduct of the user is in contravention with the Terms and Conditions of Use or the applicable laws. Lawsuit shall have a right to make such additions to, deletions from and other modifications, as it may deem fit, of the database and in the database, and/ or the manner of presenting and providing such databases, including the basic structure and features thereof, without giving notice thereof to the subscriber.</p>
                          <p>Lawsuit will not be liable or held responsible, for any delays, defaults or interruptions in the performance of the Service.</p>

                          <h5 class="tl-3-lp">6. COPYRIGHT LAWS</h5>
                          <p> Content or information available on the Web Site Lawsuit is protected by the Copyright Act 1957 and is subject to Lawsuit's Copyright.
                            Certain information and data made available by Lawsuit are the property of the content provider's and are identified as such. Lawsuit has been licensed by the content providers to store, catalogue and distribute this information to its subscribers. The information and data are protected by copyright and other intellectual property laws and no such right is deemed to have been transmitted by this arrangement to the user. As described elsewhere, the subscriber has obtained only the limited right to use, in the manner set out earlier, the information provided hereunder.</p>

                          <h5 class="tl-4-lp">ERRORS AND CORRECTIONS</h5>
                          <p> Lawsuit makes reasonable effort to ensure that the information provided on the Web Site is accurate but does not represent or warrant that the information available on or through the Web Site will be correct, accurate, reliable, uninterrupted, or timely.
                           </p>
                           <p> Lawsuit does not represent or warrant that the Web Site will be error free, free of viruses or other harmful components, or that the defects will be corrected.
                            Subscriber is responsible for implementing sufficient procedures and checkpoints to satisfy his particular requirements for accuracy of data, input and output and for maintaining a means, external to the Web Site for the reconstruction of any lost data.</p>
                            <p>
                              Subscriber user shall not post or transmit any material that contains a virus or corrupted data. Lawsuit reserves the right in its sole discretion and without any obligation, to make improvements and/or changes to its features, functionality or material, at any time.</p>
                              <p>
                                Subscriber shall also not post any material, links to any material, which violate any laws of India.</p>

                          <h5 class="tl-4-lp">DISCLAIMER OF WARRANTY</h5>
                          <p>YOU AGREE THAT USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK. THE SERVICE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED, TO YOU OR ANY OTHER PERSON RELATING IN ANY WAY TO THE SERVICE, INCLUDING ANY PART THEREOF, OR ANY WEB SITE OR OTHER CONTENT OR SERVICE THAT MAY BE ACCESSIBLE DIRECTLY OR INDIRECTLY THROUGH THE SERVICE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Lawsuit DISCLAIMS TO THE MAXIMUM EXTENT PERMITTED BY LAW ANY AND ALL (i) WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, (ii) WARRANTIES AGAINST INFRINGEMENT OF ANY THIRD PARTY INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (iii) WARRANTIES RELATING TO DELAYS, INTERRUPTIONS, ERRORS OR OMISSIONS IN THE SERVICE, OR ANY PARTY THEREOF, (iv) WARRANTIES RELATING TO THE TRANSMISSION OR DELIVERY OF THE SERVICE, AND (v) WARRANTIES OTHERWISE RELATED TO PERFORMANCE, NONPERFORMANCE, OR OTHER ACTS OR OMISSIONS BY Lawsuit OR ANY THIRD PARTY.</p>

                          <h5 class="tl-4-lp">LIMITATION OF LIABILITY</h5>
                          <p>THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. YOU SPECIFICALLY ACKNOWLEDGE THAT Fastcase IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU. NEITHER Fastcase NOR ANY OF ITS PARTNERS, AGENTS, EXECUTIVES, DIRECTORS, EMPLOYEES OR AFFILIATES SHALL BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES WHATSOEVER ARISING OUT OF USE OF THIS SERVICE OR INABILITY TO GAIN ACCESS TO OR USE THIS SERVICE OR OUT OF ANY BREACH OF ANY WARRANTY. YOU HEREBY ACKNOWLEDGE THAT THE PROVISIONS OF THIS SECTION SHALL APPLY TO ALL CONTENT ON Lawsuit.</p>

                          


                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
          </div>
                   
        </div>
          
    </div>
    <app-footer></app-footer>
  </div>