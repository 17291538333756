<div class="az-header lp-header">
    <div class="container">
        <div class="az-header-left">
            <a [routerLink]="['/']" class="az-logo"><span></span>
                <img src="assets/img/logo.png" alt="lawsuitcasefinder">
            </a>
            <a href="#" id="azMenuShow" class="az-header-menu-icon d-lg-none"><span></span></a>
        </div>
        <!-- az-header-left -->
        <div class="az-header-menu">
            <div class="az-header-menu-header">
                <a [routerLink]="['/']" class="az-logo"><span></span> </a>
                <a [routerLink]="['/']" class="close">&times;</a>
            </div>
            <!-- az-header-menu-header -->
            <ul class="nav">
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> 
                    <a href="" [routerLink]="['/']"  class="nav-link"> Home</a></li>
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> 
                    <a href="" [routerLink]="['/about']"  class="nav-link"> About Us</a></li>
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> <a
                        [routerLink]="['/datacoverage']" class="nav-link">Data Coverage</a></li>
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> 
                    <a href="" [routerLink]="['/features']" class="nav-link">Features</a></li>
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> <a
                        [routerLink]="['/packages']" class="nav-link">Packages</a></li>
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> 
                    <a href=""  [routerLink]="['/articles']"  class="nav-link">Articles</a></li>
                <!-- <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> 
                    <a href=""  [routerLink]="['/blogs']"  class="nav-link">Blogs</a></li>
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                     <a href=""  [routerLink]="['/inquiry']"  class="nav-link">Inquiry</a></li> -->
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                     <a [routerLink]="['/contact']"  class="nav-link">Contact Us</a></li>
                <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> 
                    <a  [routerLink]="['/']" class="nav-link btn-last">Get Started</a></li>
                <!-- <li class="nav-item"> <a href="" class="nav-link">My Account</a></li> -->
            </ul>
        </div>

    </div>
</div>