import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
//headers = headers.set('token', 'true');
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private httpclientbackend: HttpClient;
  constructor(
    private httpclient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpclientbackend= new HttpClient(handler);
   }
  public registerUser(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/insertuser`, user, {
      headers
    });
  }
  public getDropDowns(): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}/api/user/getregistermaster`, {
      headers
    });
  }
  public getStatebyCountyID(countyid): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}/api/user/getstatebycountryid?countryid=${countyid}`, {
      headers
    });
  }
  public CheckLoginUser(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/aouth2/signin`, user, {
      headers
    });
  }
  public getNewToken(refreshToken): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/aouth2/newticket?refreshtoken=${refreshToken}&logintype=3`, {
      headers
    });
  }
  public logout(refreshToken, userid): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/aouth2/logout?refreshtoken=${refreshToken}&userid=${userid}`, {
      headers
    });
  }

  public submitotp(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/submitotp`, user, {
      headers
    });
  }
  public resendotp(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/resendotp`, user, {
      headers
    });
  }
  public getLatestCase(payload, adddocument, userid): Observable<any> {

    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/welcome/getlatestcasedataes?adddocument=${adddocument}&userid=${userid}`, payload, {
      headers
    });
  }
  public forgotpwdsendotp(emailaddress): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/forgotpwdsendotp`, emailaddress, {
      headers
    });
  }
  public updateconfimotpuser(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/ForgotPasswordSubmitOTP`, user, {
      headers
    });
  }
  public usernewpassowrdsetup(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/usernewpassowrdsetup`, user, {
      headers
    });
  }

  public getTotalCount(): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}/api/welcome/getTotalCount`, {
      headers
    });
  }
  public getQueryResult(payload,adddocument , frompage): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/ecservice/getbareacts?adddocument=${adddocument}&frompage=${frompage}`, payload, {
      headers
    });
  }
}
