import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');

@Injectable({
  providedIn: 'root'
})

export class WelcomeService {

  constructor(
    private httpclient: HttpClient
  ) { }
  public getTree(userid,flag): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getTree?userid=${userid}&flag=${flag}`, {
      headers
    });
  }
  public getlatestcasedata(userid,courtids): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/welcome/getlatestcasedata?userid=${userid}&courtids=${courtids}`, {
      headers
    });
  }

  public saveuserjudgement(userjudgementsetting): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/user/insertuserjudgementsetting`, userjudgementsetting, {
      headers
    });
  }
  
  public saveuseractsetion(useractsetion, userid): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/welcome/insertuseractsetting?userid=${userid}`, useractsetion, {
      headers
    });
  }
  public getuserjudgementsetting(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/user/userjudgementsetting?userid=${userid}`, {
      headers
    });
  }
  public resetuseractsetting(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/welcome/resetuseractsetting?userid=${userid}`, {
      headers
    });
  }
  public getwelcomeusersettings(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/user/getwelcomeusersettings?userid=${userid}`, {
      headers
    });
  }

  public getActs(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getactsautocomplete?searchtext=${searchText}`, {
      headers
    });
  }
  public getsubscriptiondetails(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/user/getsubscriptiondetails?userid=${userid}`, {
      headers
    });
  }
  public getSectionList(searchText): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}/api/caselaw/getsectionlist?title=${searchText}`, {
      headers
    });
  }
  public getuseractsetting(userid): Observable<any> {
   
    return this.httpclient.get<any>(`${environment._apiUrl}/api/welcome/getuseractsetting?userid=${userid}`, {
      headers
    });
  }

  public getLatestCase(payload,adddocument,userid): Observable<any> {
   
    return this.httpclient.post<any>(`${environment._apiUrl}/api/welcome/getlatestcasedataes?adddocument=${adddocument}&userid=${userid}`,payload, {
      headers
    });
  }

  public saveusercourtsetting(usercourtsetting): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}/api/user/insertusercourtsetting`, usercourtsetting, {
      headers
    });
  }

  public getbareactresults(payload,adddocument, userid, frompage): Observable<any> {
 
    return this.httpclient.post<any>(`${environment._apiUrl}/api/ec/getbareacts?adddocument=${adddocument}&userid=${userid}&frompage=${frompage}`, payload, {
      headers
    });
  }
  
}
