import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EncDecService } from './enc-dec.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthdataService {
  helper = new JwtHelperService();
  constructor(private encdec: EncDecService,
    private aservice: AuthService) { }
  public getToken(): string {
    try {
      if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
        return JSON.parse(this.encdec.convertText('dec', localStorage.getItem('token')));
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }
  public setToken(token) {
    if (token) {
      localStorage.setItem('token', this.encdec.convertText('enc', JSON.stringify(token)));
    } else {
      localStorage.removeItem('token');
    }
  }
  public setUserData(data) {
    if (data) {
      
      localStorage.setItem('udata', this.encdec.convertText('enc', JSON.stringify(data)));
    } else {
      localStorage.removeItem('udata');
    }
  }
  public getUserData() {
    if (localStorage.getItem('udata')) {
      return JSON.parse(this.encdec.convertText('dec', localStorage.getItem('udata')));
    } return {};
  }
  public setSessionId(data) {
    if (data) {
      localStorage.setItem('sid', this.encdec.convertText('enc', data));
    } else {
      localStorage.removeItem('sid');
    }
  }
  public getSessionId() {
    return this.encdec.convertText('dec', localStorage.getItem('sid'));
  }
  public isAuthenticated(): boolean {
    const token: any = this.getToken();
    if (token && token !== null) {
      try {
        return this.helper.decodeToken(token.token);
      } catch (Error) {
        return false;
      }
    }
    return false;
  }
  public isTokeExpired(): boolean {
    const token: any = this.getToken();
    if (token && token !== null) {
      try {
        return this.helper.isTokenExpired(token.token);
      } catch (Error) {
        return true;
      }
    }
    return true;
  }
  public isRefreshTokeExpired(): boolean {
    const token: any = this.getToken();
    if (token && token !== null) {
      try {
        return this.helper.isTokenExpired(token.refreshtoken);
      } catch (Error) {
        return true;
      }
    }
    return true;
  }
  public getUserId(): any {
    const token: any = this.getToken();
    if (token && token != null) {
      return this.helper.decodeToken(token.token).nameid;
    } else {
      return null;
    }
  }

  public getNewTicket(rtoken) {
    this.aservice.getNewToken(rtoken).subscribe(data => {
      if (data.flag) {
        this.setToken(data.outdata);
        return this.getToken();
      } else {
        this.setToken('');
      }
    });
  }
}
