<div style="width: 500px; margin-left: 30%;margin-top: 10px;">
    <form novalidate #mainform="ngForm">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>Subject</strong></label>
                    <select aria-label="choosecountry" name="Subject" data-name="Subject" [(ngModel)]="inquiry.subject"
                        [required]="true" data-prefix="Subject" data-type="select" class="form-control">
                        <option value="">Choose</option>
                        <option>Seeking Subscription and/or Pricing Information </option>
                        <option>Question about buying a Lawsuit product online </option>
                        <option>Question about my account or bill</option>
                        <option>Experiencing a technical problem</option>
                        <option>Seeking Research Assistance</option>
                        <option>Other</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>First name</strong></label>
                    <input aria-label="fname" type="text" placeholder="First name" [(ngModel)]="inquiry.firstName"
                        name="fname" data-name="fname" [required]="true" data-prefix="First name" data-type="text"
                        class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>City</strong></label>
                    <input aria-label="City" type="text" placeholder="City" [(ngModel)]="inquiry.city" name="City"
                        data-name="City" [required]="true" data-prefix="City" data-type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>Contact number</strong></label>
                    <input aria-label="cnumber" type="text" placeholder="Contact number" [(ngModel)]="inquiry.contactNo"
                        name="cnumber" data-name="cnumber" [required]="true" data-prefix="Contact number"
                        data-type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>Email</strong></label>
                    <input aria-label="Email" type="text" placeholder="Email" [(ngModel)]="inquiry.email" name="Email"
                        data-name="Email" [required]="true" data-prefix="Email" data-type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <label for="id" aria-label="getcountryname"><strong>Post your query</strong></label>
                    <textarea aria-label="Query" type="text" placeholder="Post your query"
                        [(ngModel)]="inquiry.description" name="Query" data-name="Query" [required]="true"
                        data-prefix="Query" data-type="text" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group form-group-lg">
                    <button role="button" aria-label="makeMeLogin" class="btn btn-block btn-primary" appValidation
                        [form]="mainform" [validparent]="'form-group'" (click)="saveInquiry(mainform)">Submit</button>
                </div>
            </div>
        </div>
    </form>
</div>